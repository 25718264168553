import React, { Component } from 'react';
import { GLGlobal, GLUtil, Role } from 'gl-commonui';
import { PathConfig } from "@app/config/pathconfig";
import { GSAdminAction } from '@app/util/index';
import { DashboardLocale, GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { SchoolModel, SchoolModelPropNames } from '@app/service/schools';
import { GLGridColumnProps } from '@app/components/gl-grid';
import { RoleGrid } from './role-grid';
import { Icon } from 'gl-commonui/lib/antd-min';

const ColumnSortingMap: Map<string, string> = new Map([
    [SchoolModelPropNames.regionName, 'regionName'],
    [SchoolModelPropNames.name, 'schoolName'],
    [SchoolModelPropNames.subscriptionTypeText, 'subscriptionType'],
    [SchoolModelPropNames.digitalLicense, 'digitalLicenseCount'],
    [SchoolModelPropNames.textbookLicense, 'textbookLicenseCount'],
    [SchoolModelPropNames.littleSeedLicense, 'littleSEEDCount']
]);

export const RegionAdminGridDefaultSorter = { 
    columnName: SchoolModelPropNames.name, 
    columnSortName: ColumnSortingMap.get(SchoolModelPropNames.name), 
    ascending: true 
};

export const RegionAdminGridDefaultSorter2 = { 
    columnName: SchoolModelPropNames.regionName, 
    columnSortName: ColumnSortingMap.get(SchoolModelPropNames.regionName), 
    ascending: true 
};

export class RegionAdminGrid extends RoleGrid<SchoolModel> {

    formatMessage = GLGlobal.intl.formatMessage;
    static defaultProps = {
        searchPlaceHoderId: DashboardLocale.SearchPlaceHolderSchool,
        defaultSorter: RegionAdminGridDefaultSorter
    };

    constructor(props, context) {
        super(props, context);
    }

    getColumns(props): GLGridColumnProps<SchoolModel>[] {
        const regionResources = props.roleResources && props.roleResources.find(roleResource=> roleResource.role == Role.RegionAdmin);
        const hasRegionColumns = 'roleResources' in props && 
            regionResources  && regionResources.resource.length > 1;
        let columns: GLGridColumnProps<SchoolModel>[] = [
            {
                title: GSAdminLocale.RegionsModelName,
                dataIndex: SchoolModelPropNames.regionName,
                width: '11%',
                visible: hasRegionColumns,
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSAdminAction.ListRegion)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Region , {regionId: school.regionId})}" > <span>${text}</span> </a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                },
                className:'navigation-col'
            },            
            {
                title: GSAdminLocale.SchoolModelName,
                dataIndex: SchoolModelPropNames.name,
                width: '11%',
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSAdminAction.ListSchool)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Schools , {regionId: school.regionId, schoolId: school.id})}" > <span>${text}</span> </a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                },
                className:'navigation-col'
            },
            {
                title: GSAdminLocale.SchoolModelCode,
                dataIndex: SchoolModelPropNames.gsNumber,
                width: '10%'
            },
            {
                title: GSAdminLocale.SchoolModelSubscriptionType,
                dataIndex: SchoolModelPropNames.subscriptionTypeText,
                width: '13%'
            },
            {
                title: SchoolLocale.ClassesColumnTrainerName,
                dataIndex: SchoolModelPropNames.trainerName,
                sorter: false,
                width:'8%',
                render: (text, school, index) => {
                    if (school.hasMultipleTrainer) {
                        return `<span>${text}</span> 
                         <a title = ${this.formatMessage({ id: GSAdminLocale.HomeShowMore })} href="${GLUtil.pathStringify(PathConfig.SchoolTrainers , {regionId: school.regionId, schoolId: school.id})}"> +</a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                    
                },
            },
            {
                title: SchoolLocale.ClassesColumnStudentCount,
                dataIndex: SchoolModelPropNames.studentCount,
                width:'8%'
            },
            {
                title: GSAdminLocale.SchoolModelDigitalLicense,
                dataIndex: SchoolModelPropNames.digitalLicense,
                width:'11%'
            },
            {
                title: GSAdminLocale.SchoolModelTextbookLicense,
                dataIndex: SchoolModelPropNames.textbookLicense,
                width:'13%'
            },
            {
                title: GSAdminLocale.SchoolModelDualLicense,
                dataIndex: SchoolModelPropNames.bothCount,
                width:'10%'
            },
            {
                title: GSAdminLocale.SchoolModelLittleSEEDLicense,
                dataIndex: SchoolModelPropNames.littleSeedLicense,
                width:'13%'
            }
        ];
        return columns;
    }

    getColumnSortingMap(): Map<string, string> {
        return ColumnSortingMap;
    }
}