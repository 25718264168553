import React, { Component } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { GLGlobal, GLUtil } from 'gl-commonui';
import { PathConfig } from "@app/config/pathconfig";
import { GSAdminAction } from '@app/util/index';
import { DashboardLocale, GSAdminLocale } from '@app/locales/localeid';
import { RegionModel, RegionModelPropNames } from '@app/service/admin/regions/model';
import { GLGridColumnProps } from '@app/components/gl-grid';
import { RoleGrid } from './role-grid';

const ColumnSortingMap: Map<string, string> = new Map([
    [RegionModelPropNames.name, 'regionName'],
    [RegionModelPropNames.gsContentversion, 'gsVersion'],
    [RegionModelPropNames.lsContentversion, 'lsVersion']
]);

export const TrainingAdminGridDefaultSorter = { 
    columnName: RegionModelPropNames.name, 
    columnSortName: ColumnSortingMap.get(RegionModelPropNames.name), 
    ascending: true 
};

export class TrainingAdminGrid extends RoleGrid<RegionModel> {
    
    static defaultProps = {
        searchPlaceHoderId: DashboardLocale.SearchPlaceHolderRegion,
        defaultSorter: TrainingAdminGridDefaultSorter
    };

    constructor(props, context) {
        super(props, context);
    }

    getColumns(): GLGridColumnProps<RegionModel>[] {
        let columns: GLGridColumnProps<RegionModel>[] = [
            {
                title: GSAdminLocale.RegionsModelName,
                dataIndex: RegionModelPropNames.name,
                width: '35%',
                render: (text, region, index) => {
                    if (GLGlobal.isActionValid(GSAdminAction.ListRegion)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Region , {regionId: region.id})}" > <span>${text}</span> </a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                },
                className:'navigation-col'
            },
            {
                title: GSAdminLocale.RegionModelGSContentVersion,
                dataIndex: RegionModelPropNames.gsContentversion,
                width: '20%'
            },
            {
                title: GSAdminLocale.RegionModelLSContentVersion,
                dataIndex: RegionModelPropNames.lsContentversion,
                width: '20%'
            },
            {
                title: GSAdminLocale.RegionListStudents,
                dataIndex: RegionModelPropNames.studentCount,
                width:'15%'
            },
            {
                title: GSAdminLocale.RegionListTeachers,
                dataIndex: RegionModelPropNames.teacherCount,
                width:'15%'
            }
        ];
        return columns;
    }

    getColumnSortingMap(): Map<string, string> {
        return ColumnSortingMap;
    }    
}