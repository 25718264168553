import React, { Component } from "react";
import { MainTitle, SecondaryTitle } from "@app/components/school/school-page-title";
import "./dashboard.less";
import "../ongoing/component/progress.less";
import { fmtMsg, TYPES, lazyInject, ContextHelper, DateHelper } from "@app/util";
import { SchoolLocale } from "@app/locales/localeid";
import { IVisitationService, TeacherDashboardTitleModel } from "@app/service/coach/visitation";
import {
    VisitationTeacherStage,
    VisitationType,
    PreVisitationFormStatus,
    ReviewTeacherFormStatus,
    ReviewCoachFormStatus,
    LVAResourceFormStatus,
} from "@app/util/coach/enum";
import { Divider, Icon } from "antd-min";
import { GLUtil } from "gl-commonui";
import { SchoolPathConfig, PathConfig } from "@app/config/pathconfig";
import { orderBy } from "lodash";
import {
    SetUpBoldIcon,
    SetUpIcon,
    SupportQuestionnaireBoldIcon,
    SupportQuestionnaireIcon,
    VideoManagerIcon,
    VideoManagerBoldIcon,
    TeacherFeedbackIcon,
    TeacherFeedbackBoldIcon,
    CoachFeedbackIcon,
    CoachFeedbackBoldIcon,
} from "@app/components/svgicon";
import { VisitTracking, VisitTrackingStep } from "../ongoing/component/visit-tracking";
import { Action, Actions, Container } from "@app/components";

interface TeacherDashboardProps {
    hideTitle?: boolean;
}
interface TeacherDashboardStates {
    loading: boolean;
    list?: any[];
}

export class TeacherDashboard extends Component<TeacherDashboardProps, TeacherDashboardStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            list: [],
        };
    }
    componentWillMount() {
        this.getOngoingVisitList();
    }
    getOngoingVisitList() {
        this.setState({ loading: true });

        this.visitationservice.getTeacherRelated({}).then((data) => {
            this.setState({
                loading: false,
                list: orderBy(this.formatVisitList(data), ["type", "startDate"], ["asc", "desc"]),
            });
        });
    }
    formatVisitList(data) {
        const userId = ContextHelper.getUserLoginInfo().profile.sub;
        return (
            data &&
            data.filter((v) => {
                const {
                    associatedInfo: { visitationTeacher },
                } = v;
                const teacher = visitationTeacher && visitationTeacher.find((v) => v.teacherId == userId);
                const isCompleted =
                    teacher && (teacher.stage == VisitationTeacherStage.Completed || teacher.stage == VisitationTeacherStage.CompletedByCoach);
                if (!isCompleted) {
                    return v;
                }
            })
        );
    }
    render() {
        const { list } = this.state;
        return (
            <>
                <Container className="t-dash__container">
                    {!this.props.hideTitle && <MainTitle plain={fmtMsg({ id: SchoolLocale.HomeTeacherDashboard })} />}
                    {!!list.length && (
                        <div className="t-dash__wrapper">
                            <SecondaryTitle className="t-dash__visit-heading" title={SchoolLocale.VisitationOngoingVisit}>
                                <Actions noHeight>
                                    <Action
                                        materialIcon="history"
                                        textLocaleId={SchoolLocale.VisitationViewFeedbackHistory}
                                        linkProps={{
                                            to: PathConfig.MyActivity,
                                        }}
                                    />
                                </Actions>
                            </SecondaryTitle>
                            <OngoingVisitList list={list} />
                        </div>
                    )}
                </Container>
                <Divider />
            </>
        );
    }
}

interface OngoingVisitListProps {
    list: any[];
}

interface OngoingVisitListStates {}

class OngoingVisitList extends Component<OngoingVisitListProps, OngoingVisitListStates> {
    userId = ContextHelper.getUserLoginInfo().profile.sub;
    constructor(props) {
        super(props);
    }
    progressTypes() {
        return {
            Onsite: new Set([
                [VisitationTeacherStage.ScheduleDate, SetUpIcon, SetUpBoldIcon],
                [VisitationTeacherStage.PrevisitationForm, SupportQuestionnaireIcon, SupportQuestionnaireBoldIcon],
                [VisitationTeacherStage.ViewCoachFeedback, TeacherFeedbackIcon, TeacherFeedbackBoldIcon],
                [VisitationTeacherStage.GiveCoachFeedback, CoachFeedbackIcon, CoachFeedbackBoldIcon],
            ]),
            LVA: new Set([
                [VisitationTeacherStage.ScheduleDate, SetUpIcon, SetUpBoldIcon],
                [VisitationTeacherStage.PrevisitationForm, SupportQuestionnaireIcon, SupportQuestionnaireBoldIcon],
                [VisitationTeacherStage.VideoManager, VideoManagerIcon, VideoManagerBoldIcon],
                [VisitationTeacherStage.ViewCoachFeedback, TeacherFeedbackIcon, TeacherFeedbackBoldIcon],
                [VisitationTeacherStage.GiveCoachFeedback, CoachFeedbackIcon, CoachFeedbackBoldIcon],
            ]),
        };
    }
    renderStages(visitation, index) {
        const isOnsite = visitation.type == VisitationType.OnSite;
        let currentProgress = isOnsite ? this.progressTypes().Onsite : this.progressTypes().LVA;

        const steps = [...currentProgress].map((v, k) => {
            const { color, title, teacherStage } = this.getClassName(visitation, v[0]);
            const { stageName, coachName, formattedStartDate, statusText } = title;
            const isCompleted = teacherStage == v[0];
            return {
                icon:
                    typeof v[1] === "string" ? (
                        <div className="circle">
                            <Icon type={v[1]} />
                        </div>
                    ) : color.includes("green selected") ? (
                        React.createElement(v[2] as any, {})
                    ) : (
                        React.createElement(v[1] as any, {})
                    ),
                title: title.stageName,
                description: (
                    <>
                        {coachName && (
                            <div className="coach-name" title={coachName}>
                                {coachName}
                            </div>
                        )}
                        {formattedStartDate && <div>{formattedStartDate}</div>}
                        {statusText && <div>{statusText}</div>}
                    </>
                ),
                completed: isCompleted,
                active: false,
                link: isCompleted
                    ? GLUtil.pathStringify(SchoolPathConfig.TeacherVisitation, {
                          regionId: visitation.regionId,
                          schoolId: visitation.schoolId,
                          visitationId: visitation.id,
                      })
                    : undefined,
            } as any as VisitTrackingStep;
        });

        return (
            <div key={index} className="t-dash__visit-card">
                <VisitTracking steps={steps} />
            </div>
        );
    }

    private getClassName(visitation, state) {
        let color = "";
        const teacherStage = this.formatTeacherStage(visitation);
        const title = this.formatTeacherState(visitation, state, teacherStage);
        if (teacherStage > state) {
            color = `green`;
        } else if (teacherStage == state) {
            color = `orange`;
        } else {
            color = `black`;
        }
        return {
            color,
            title,
            teacherStage,
        };
    }
    formatTeacherStage(visitation) {
        const {
            associatedInfo: { visitationTeacher },
        } = visitation;
        const teacher = visitationTeacher.find((t) => t.teacherId == this.userId);
        return teacher && teacher.stage;
    }
    formatTeacherState(visitation, state, teacherStage) {
        switch (teacherStage) {
            case VisitationTeacherStage.ScheduleDate:
            case VisitationTeacherStage.PrevisitationForm:
                return this.formatPrevisitationForm(visitation, state);
            case VisitationTeacherStage.VideoManager:
                return this.formatVideoManager(visitation, state);
            case VisitationTeacherStage.ViewCoachFeedback:
                return this.formatFeedback(visitation, state);
            case VisitationTeacherStage.GiveCoachFeedback:
                return this.formatCoachFeedback(visitation, state);
            case VisitationTeacherStage.Completed:
            case VisitationTeacherStage.CompletedByCoach:
            default:
                return {} as TeacherDashboardTitleModel;
        }
    }
    renderTeacherStageNames(stage) {
        switch (stage) {
            case VisitationTeacherStage.ScheduleDate:
                return fmtMsg({ id: SchoolLocale.VisitationScheduleDate });
            case VisitationTeacherStage.PrevisitationForm:
                return fmtMsg({ id: SchoolLocale.VisitationPrevisitationForm });
            case VisitationTeacherStage.VideoManager:
                return fmtMsg({ id: SchoolLocale.VisitationVideoManager });
            case VisitationTeacherStage.ViewCoachFeedback:
                return fmtMsg({ id: SchoolLocale.VisitationFeedback });
            case VisitationTeacherStage.GiveCoachFeedback:
                return fmtMsg({ id: SchoolLocale.VisitationCoachFeedback });
        }
    }
    formatPrevisitationForm(visitation, state) {
        const {
            type,
            startDate,
            coachName,
            associatedInfo: { preVisitationForm, lvaResourceForm },
        } = visitation;
        const formattedStartDate = DateHelper.toLocalStringFromUTC(startDate);
        const isOnsite = type == VisitationType.OnSite;
        switch (state) {
            case VisitationTeacherStage.ScheduleDate:
                const stageName = isOnsite
                    ? fmtMsg({ id: SchoolLocale.VisitationCoachVisitationText })
                    : fmtMsg({ id: SchoolLocale.VisitationLVAVisitationText });
                return {
                    stageName,
                    coachName,
                    formattedStartDate,
                };
            case VisitationTeacherStage.PrevisitationForm:
                let pvfItem = preVisitationForm && preVisitationForm.find((v) => v.teacherId == this.userId);
                let pvfStatusText = pvfItem && this.preVisitationFormStatus(pvfItem.status);
                return {
                    stageName: this.renderTeacherStageNames(state),
                    statusText: pvfStatusText,
                };
            case VisitationTeacherStage.VideoManager:
            case VisitationTeacherStage.ViewCoachFeedback:
            case VisitationTeacherStage.GiveCoachFeedback:
                return {
                    stageName: this.renderTeacherStageNames(state),
                };
        }
    }
    preVisitationFormStatus(status) {
        switch (status) {
            case PreVisitationFormStatus.Initialized:
            case PreVisitationFormStatus.IgnoreInitialized:
            case PreVisitationFormStatus.IgnorePending:
                return "";
            case PreVisitationFormStatus.Pending:
                return fmtMsg({ id: SchoolLocale.VisitationReceivedText });
            case PreVisitationFormStatus.Completed:
                return fmtMsg({ id: SchoolLocale.VisitationSentText });
        }
    }
    formatVideoManager(visitation, state) {
        const {
            type,
            startDate,
            coachName,
            associatedInfo: { lvaResourceForm },
        } = visitation;
        const formattedStartDate = DateHelper.toLocalStringFromUTC(startDate);
        const isOnsite = type == VisitationType.OnSite;
        switch (state) {
            case VisitationTeacherStage.ScheduleDate:
                const stageName = isOnsite
                    ? fmtMsg({ id: SchoolLocale.VisitationCoachVisitationText })
                    : fmtMsg({ id: SchoolLocale.VisitationLVAVisitationText });
                return {
                    stageName,
                    coachName,
                    formattedStartDate,
                };
            case VisitationTeacherStage.VideoManager:
                let item = lvaResourceForm && lvaResourceForm.find((v) => v.teacherId == this.userId);
                let statusText = item && this.formatVideoManagerStatus(item.status);
                return {
                    stageName: this.renderTeacherStageNames(state),
                    statusText: statusText,
                };
            case VisitationTeacherStage.PrevisitationForm:
            case VisitationTeacherStage.ViewCoachFeedback:
            case VisitationTeacherStage.GiveCoachFeedback:
                return {
                    stageName: this.renderTeacherStageNames(state),
                };
        }
    }
    formatVideoManagerStatus(status) {
        return status == LVAResourceFormStatus.Sent
            ? fmtMsg({ id: SchoolLocale.VisitationSentText })
            : fmtMsg({ id: SchoolLocale.VisitationPendingText });
    }
    formatFeedback(visitation, state) {
        const {
            type,
            startDate,
            coachName,
            associatedInfo: { reviewTeacherForm },
        } = visitation;
        const formattedStartDate = DateHelper.toLocalStringFromUTC(startDate);
        const isOnsite = type == VisitationType.OnSite;
        switch (state) {
            case VisitationTeacherStage.ScheduleDate:
                const stageName = isOnsite
                    ? fmtMsg({ id: SchoolLocale.VisitationCoachVisitationText })
                    : fmtMsg({ id: SchoolLocale.VisitationLVAVisitationText });
                return {
                    stageName,
                    coachName,
                    formattedStartDate,
                };

            case VisitationTeacherStage.ViewCoachFeedback:
                const item = reviewTeacherForm && reviewTeacherForm.find((v) => v.teacherId == this.userId);
                const tvStatusText = item && this.formatFeedbackStatus(item.status);
                return {
                    stageName: this.renderTeacherStageNames(state),
                    statusText: tvStatusText,
                };
            case VisitationTeacherStage.PrevisitationForm:
            case VisitationTeacherStage.VideoManager:
            case VisitationTeacherStage.GiveCoachFeedback:
                return {
                    stageName: this.renderTeacherStageNames(state),
                };
        }
    }
    formatFeedbackStatus(status) {
        switch (status) {
            case ReviewTeacherFormStatus.Initialized:
            case ReviewTeacherFormStatus.Draft:
            default:
                return fmtMsg({ id: SchoolLocale.VisitationPendingText });
            case ReviewTeacherFormStatus.Completed:
            case ReviewTeacherFormStatus.Viewed:
                return fmtMsg({ id: SchoolLocale.VisitationReceivedText });
        }
    }
    formatCoachFeedback(visitation, state) {
        const {
            type,
            startDate,
            coachName,
            associatedInfo: { reviewCoachForm },
        } = visitation;
        const formattedStartDate = DateHelper.toLocalStringFromUTC(startDate);
        const isOnsite = type == VisitationType.OnSite;
        switch (state) {
            case VisitationTeacherStage.ScheduleDate:
                const stageName = isOnsite
                    ? fmtMsg({ id: SchoolLocale.VisitationCoachVisitationText })
                    : fmtMsg({ id: SchoolLocale.VisitationLVAVisitationText });
                return {
                    stageName,
                    coachName,
                    formattedStartDate,
                };
            case VisitationTeacherStage.PrevisitationForm:
            case VisitationTeacherStage.VideoManager:
            case VisitationTeacherStage.ViewCoachFeedback:
                return {
                    stageName: this.renderTeacherStageNames(state),
                };
            case VisitationTeacherStage.GiveCoachFeedback:
                const item = reviewCoachForm && reviewCoachForm.find((v) => v.teacherId == this.userId);
                const statusText = item && this.formatCoachFeedbackStatus(item.status);
                return {
                    stageName: this.renderTeacherStageNames(state),
                    statusText: statusText,
                };
        }
    }
    formatCoachFeedbackStatus(status) {
        switch (status) {
            case ReviewCoachFormStatus.Initialized:
            case ReviewCoachFormStatus.Draft:
                return fmtMsg({ id: SchoolLocale.VisitationPendingText });
            case ReviewCoachFormStatus.Completed:
                return fmtMsg({ id: SchoolLocale.VisitationSentText });
        }
    }
    render() {
        const { list } = this.props;
        return list.map(this.renderStages.bind(this));
    }
}
