import React, {useEffect, useRef, useState} from "react";
import {Empty, Icon, Input, Pagination, Spin, Tabs} from "antd-min";
import classNames from "classnames";
import {connect, GLGlobal, GLLocale, PaginationParams, ResourceType, RoleName, withRouter} from "gl-commonui";
import {FlexGrid as ReactFlexGrid, FlexGridColumn} from "wijmo/wijmo.react.grid";
import {
    AllowMerging,
    AllowResizing,
    CellType,
    FlexGrid,
    GroupRow,
    HeadersVisibility,
    SelectionMode
} from "wijmo/wijmo.grid";
import {GroupPanel} from "wijmo/wijmo.react.grid.grouppanel";
import {FlexGridXlsxConverter} from "wijmo/wijmo.grid.xlsx";
import {CollectionView, removeClass, setCss, SortDescription} from "wijmo/wijmo";
import {ContextHelper, fmtMsg, htmlEncodeByRegExp, ListStates, Wijmo2ExcelHelper} from "@app/util";
import {StateType} from "@app/states";
import {getData, getRoleResources, resetSchoolClasses, RoleResource, setLoading} from "@app/states/dashboard";
import {RegionModel, RegionModelPropNames} from "@app/service/admin/regions";
import {SchoolModel} from "@app/service/schools";
import {ClassesModel} from "@app/service/class";
import {DashboardLocale} from "@app/locales/localeid";
import {NoResourcesPage} from "../component";
import {
    defaultSorting,
    excelExport,
    getFilterOnColumns,
    onFormatCell,
    RoleColumnSettings,
    roleText,
    sortColumn2UIColumnMap,
    tabsFlex,
    tabsGroupPanelVisibility,
    tabsPagination,
    tabsSorter,
    tabsViews,
    uiColumn2SortColumnMap,
} from "./";
import "./landing-tabs.less";
import {tabsListStatesStore} from "./tabs-helper";
import {ListStatesRadios, ListStatesStore} from "@app/components";
import {wijmoGridResponsive} from "@app/util/wijmo";
import {CoachDashboard} from "@app/page/coach/dashboard";
import {TeacherDashboard} from "@app/page/coach/teacher/dashboard";
import {FlexGridContextMenu} from "@app/components/grid/context-menu";
import { Modal } from 'antd';
import {UnitPlan} from "@app/page/school/class/components/unitplan";

interface LandingTabsProps {
    regions?: RegionModel[];
    schools?: SchoolModel[];
    campuses?: any[];
    schoolClasses?: ClassesModel[];
    extraSchoolClasses?: ClassesModel[];
    regions4Export?: RegionModel[];
    schools4Export?: SchoolModel[];
    schoolClasses4Export?: ClassesModel[];
    ownerRegions?: RoleResource[];
    ownerSchools?: RoleResource[];
    ownerCampuses?: RoleResource[];
    href?: string;
    roleResources?: RoleResource[];
    totalCount?: number;
    activeCount?: number;
    futureCount?: number;
    extraTotalCount?: number;
    extraData?: { allCnt: number; activeCnt: number; inActiveCnt: number };
    loading?: boolean;
    langLoaded?: string;
    reloadCoachData?: boolean;
    getData?: (d) => void;
    getRoleResources?: (d) => void;
    setLoading?: (loading: boolean) => void;
    resetSchoolClasses?: () => void;
}
export interface GLGridColumnProps {
    titleId?: string;
    visible?: boolean;
    allowMerging?: boolean;
    render?: (text: any, record: any, index: number) => string | React.ReactNode;
}

export const LandingTabs = withRouter(
    connect(
        ({
            dashboard: {
                regions,
                schools,
                campuses,
                ownerRegions,
                ownerSchools,
                ownerCampuses,
                schoolClasses,
                extraSchoolClasses,
                regions4Export,
                schools4Export,
                schoolClasses4Export,
                href,
                roleResources,
                totalCount,
                activeCount,
                futureCount,
                extraTotalCount,
                extraData,
                loading,
                reloadCoachData,
            },
            intl: { langLoaded },
        }: StateType) => ({
            regions,
            schools,
            campuses,
            schoolClasses,
            extraSchoolClasses,
            ownerRegions,
            ownerSchools,
            ownerCampuses,
            regions4Export,
            schools4Export,
            schoolClasses4Export,
            href,
            roleResources,
            totalCount,
            activeCount,
            futureCount,
            extraTotalCount,
            extraData,
            loading,
            langLoaded,
            reloadCoachData,
        }),
        { getData, getRoleResources, setLoading, resetSchoolClasses }
    )((props: LandingTabsProps) => {
        const [view, setView] = useState(tabsViews); // different wijmo collectionView for different tabs
        const [flex, setFlex] = useState(tabsFlex); // used for group panel
        const [groupPanelVisibility, setGroupPanelVisibility] = useState(new Map(tabsGroupPanelVisibility)); // used for control group panel visibility
        const [activeTabKey, setActiveTabKey] = useState(null);
        const [navInfo, setNavInfo] = useState(new Map(tabsPagination));
        const [filter, setFilter] = useState("");
        const fillterRef = useRef(filter);
        const [inGroupMode, setInGroupMode] = useState(false);
        const [currentTab, setCurrentTab] = useState("");
        const [currentSchoolClass, setCurrentSchoolClass] = useState(null);
        const mergedSchoolClasses = props.schoolClasses.length > 0 ? props.schoolClasses : props.extraSchoolClasses;

        // load initial tab data
        useEffect(() => {
            loadTabData(roleNames[0]);
        }, []);

        useEffect(() => {
            if (!isDataEmpty(activeTabKey, mergedSchoolClasses)) {
                updateFlexAndView(activeTabKey, mergedSchoolClasses);
            }
        }, [mergedSchoolClasses]);

        useEffect(() => {
            if (!isDataEmpty(activeTabKey, props.regions)) {
                updateFlexAndView(activeTabKey, props.regions);
            }
        }, [props.regions]);

        useEffect(() => {
            if (!isDataEmpty(activeTabKey, props.schools)) {
                updateFlexAndView(activeTabKey, props.schools);
            }
        }, [props.schools]);

        useEffect(() => {
            if (!isDataEmpty(activeTabKey, props.campuses)) {
                updateFlexAndView(activeTabKey, props.campuses);
            }
        }, [props.campuses]);

        useEffect(() => {
            updateColumnHeaders();
        }, [props.langLoaded]);

        useEffect(() => {
            if (!isDataEmpty(activeTabKey, props.regions4Export)) {
                saveData2Excel(activeTabKey, props.regions4Export);
            }
        }, [props.regions4Export]);

        useEffect(() => {
            if (!isDataEmpty(activeTabKey, props.schools4Export)) {
                saveData2Excel(activeTabKey, props.schools4Export);
            }
        }, [props.schools4Export]);

        useEffect(() => {
            if (!isDataEmpty(activeTabKey, props.schoolClasses4Export)) {
                saveData2Excel(activeTabKey, props.schoolClasses4Export);
            }
        }, [props.schoolClasses4Export]);


        useEffect(() => {
            // the data on the grid of current tab is refreshed so that the formatting of numbers change when
            // a different language is selected.
            const tab = currentTab.length ? currentTab : roleNames[0];
            const gridView = view.get(tab) ? view.get(tab) : {};
            // If the user is logged in with in user other than sadmin the view.get(tab)(CollectionView) is an empty object.
            // So if the CollectionView is empty skip refreshing the grid.
            if (Object.keys(gridView).length && gridView.refresh()) {
                gridView.refresh();
            }
        }, [GLGlobal.intl.locale])

        let roleNames = null;
        let resizingRows = false;
        const resultsPerPage = 20;

        // set rolenames, and active tab
        if (!roleNames) {
            let userRoles = ContextHelper.getUserRoles(true);
            if (userRoles.has(RoleName.globalHead) && userRoles.has(RoleName.systemAdmin)) {
                userRoles.delete(RoleName.globalHead);
            }
            userRoles.has(RoleName.parent) && userRoles.delete(RoleName.parent);
            // remove training Manager role, to stop rendering training manager tab on dashboard
            userRoles.has(RoleName.trainingManager) && userRoles.delete(RoleName.trainingManager);
            // remove contentAdmin role, to stop rendering ContentAdmin tab on dashboard
-           userRoles.has(RoleName.contentAdmin) && userRoles.delete(RoleName.contentAdmin);
            roleNames = ContextHelper.sortUserRoles([...userRoles] as RoleName[]);
        }

        if (!activeTabKey && roleNames) {
            setActiveTabKey(roleNames.length > 0 ? roleNames[0] : "");
        }

        const getTotalItemCount = (roleName) => {
            const { totalCount, activeCount, futureCount } = props;
            const currentListStore = tabsListStatesStore.get(roleName);
            switch (roleName) {
                case RoleName.trainer:
                case RoleName.schoolAdmin:
                case RoleName.campusAdmin:
                case RoleName.teacher:
                case RoleName.accountManager:
                    return ListStatesStore.getStateCnt(
                        totalCount,
                        activeCount,
                        { listState: currentListStore.getState() },
                        futureCount
                    );
                default:
                    return totalCount;
            }
        };

        const updateFlexAndView = (roleName: RoleName, source: any) => {
            // update collectionView for activeTabKey
            let newCollectionView = createCollectionView(source, activeTabKey);
            // update flex
            let newFlex = flex;
            let flexToEdit = flex.get(roleName);
            flexToEdit.itemsSource = newCollectionView;
            newFlex.set(roleName, flexToEdit);
            setFlex(newFlex);
            // update view
            let newView = view;
            newView.set(roleName, newCollectionView);
            setView(newView);
            // update nav values
            const totalItemCount = groupPanelVisibility.get(roleName)
                ? newCollectionView.totalItemCount
                : getTotalItemCount(roleName);
            updateNavInfo(roleName, { totalItemCount, currentPage: navInfo.get(roleName).currentPage, resultsPerPage });
        };

        const updateNavInfo = (roleName: RoleName, pagination: any) => {
            let newNavInfo = groupPanelVisibility.get(roleName) ? new Map(tabsPagination) : navInfo;
            newNavInfo.set(roleName, pagination);
            setNavInfo(newNavInfo);
        };

        const isDataEmpty = (roleName: RoleName, dataSource: any[]) => {
            const gpVisible = groupPanelVisibility.get(roleName);
            return gpVisible
                ? dataSource.length === 0 && props.totalCount === 0
                : dataSource.length === 0 && props.totalCount === 0 && filter == "";
        };

        const getTabQueryParams = (roleName: RoleName, listState: ListStates) => {
            switch (roleName) {
                case RoleName.trainer:
                case RoleName.schoolAdmin:
                case RoleName.campusAdmin:
                case RoleName.teacher:
                case RoleName.accountManager:
                    switch (listState) {
                        case ListStates.Active:
                            return { disabled: false, future: null };
                        case ListStates.Future:
                            return { disabled: false, future: true };
                        default:
                            return { disabled: null, future: null };
                    }
                default:
                    return { disabled: null, future: null };
            }
        };

        const loadTabData = (activeKey: RoleName, filterValue = null, listState = null) => {
            setCurrentTab(activeKey);
            const gpVisible = groupPanelVisibility.get(activeKey);
            // calculate sort params when loading data
            const sortParams = gpVisible ? { sortBy: null, isDescending: null } : tabsSorter.get(activeKey);
            // calculate paging params when loading data
            const pagingParams = gpVisible
                ? null
                : new PaginationParams(navInfo.get(activeKey).currentPage, resultsPerPage).toRequest();
            const currentListStore = tabsListStatesStore.get(activeKey);
            const filterText = filterValue == null ? (gpVisible ? null : fillterRef.current) : filterValue;
            props.getData({
                query: {
                    roleName: activeKey,
                    filterText: filterText ? filterText.trim() : filterText,
                    ...getTabQueryParams(activeKey, listState == null ? currentListStore.getState() : listState),
                    ...sortParams,
                    ...pagingParams,
                },
            });
        };

        const onTabChanged = (activeKey) => {
            setActiveTabKey(activeKey);
            // reset filter from state
            setFilter("");
            fillterRef.current = "";
            // reset school classes first when activekey is trainer, school admin, campus admin, teacher
            const mergedSchoolClasses = props.schoolClasses.length > 0 ? props.schoolClasses : props.extraSchoolClasses;
            if (
                [RoleName.trainer, RoleName.schoolAdmin, RoleName.campusAdmin, RoleName.teacher, RoleName.accountManager].some(
                    (role) => role === activeKey
                ) &&
                mergedSchoolClasses.length
            ) {
                props.resetSchoolClasses();
            }
            // load data with filter = ""
            loadTabData(activeKey, "");
        };

        const initGrid = (grid: FlexGrid, source, roleName: RoleName) => {
            // responsive the grid
            wijmoGridResponsive(grid);
            // create a new collection view
            let viewFromSource = createCollectionView(source, roleName);
            // update paging
            viewFromSource.onPageChanged();
            // save view
            let newView = view;
            newView.set(roleName, viewFromSource);
            setView(newView);
            // save flex
            let newFlex = flex;
            flex.set(roleName, grid);
            setFlex(newFlex);
            // Add custom multiple sorting
            // listen for clicks on column headers
            grid.sortingColumn.addHandler((s, e) => {
                let currentView = s.collectionView;
                let sortingDescriptions = currentView.sortDescriptions;
                let groups = currentView.groups;
                const col = s.columns[e.col];
                const asc = col.currentSort != "+";
                const gpVisible = groupPanelVisibility.get(roleName);
                if (!gpVisible) {
                    //sorting on back-end
                    e.cancel = true;
                    const sortBy = uiColumn2SortColumnMap.has(col.binding)
                        ? uiColumn2SortColumnMap.get(col.binding)
                        : col.binding;
                    tabsSorter.set(roleName, { sortBy, isDescending: !asc });
                    loadTabData(roleName);
                    return;
                }
                // if the control key is pressed, clear all sorts
                if (e.data.ctrlKey) {
                    sortingDescriptions.clear();
                    return;
                }
                if (groups) {
                    // prevent default behavior
                    e.cancel = true;
                    let sd = new SortDescription(col.binding, asc);
                    currentView.deferUpdate(() => {
                        currentView.stableSort = true;
                        // find current sorting for groupDescriptions columns
                        let currentGdSorting = {};
                        currentView.groupDescriptions &&
                            currentView.groupDescriptions.forEach((gd) => {
                                currentView.sortDescriptions &&
                                    currentView.sortDescriptions.forEach((sortDesc) => {
                                        if (gd.propertyName == sortDesc.property && gd.propertyName !== col.binding) {
                                            currentGdSorting[gd.propertyName] = sortDesc.ascending;
                                        }
                                    });
                            });

                        sortingDescriptions.clear();
                        currentView.groupDescriptions.forEach((gd) => {
                            var gsd = new SortDescription(
                                gd.propertyName,
                                typeof currentGdSorting[gd.propertyName] === "boolean"
                                    ? currentGdSorting[gd.propertyName]
                                    : true
                            );
                            if (gsd.property !== col.binding) {
                                sortingDescriptions.push(gsd);
                            }
                        });
                        sortingDescriptions.push(sd);
                    });
                }
            });

            // Add grid click event listener
            grid.addEventListener(grid.hostElement, "click", onGridClick);

            // Render context menu
            new FlexGridContextMenu(grid);
        };

        const createCollectionView = (source, roleName: RoleName) => {
            const sorter = tabsSorter.get(roleName);
            const needUseColumnMap = !(
                roleName == RoleName.regionAdmin && sorter.sortBy == RegionModelPropNames.regionEnglishName
            );
            const columnName =
                needUseColumnMap && sortColumn2UIColumnMap.has(sorter.sortBy)
                    ? sortColumn2UIColumnMap.get(sorter.sortBy)
                    : sorter.sortBy;
            const columns = getRoleGridConfig().get(roleName).columns;
            return new CollectionView(source, {
                pageSize: resultsPerPage,
                pageChanged: ({ totalItemCount, pageIndex }) => {
                    updateNavInfo(roleName, { totalItemCount, currentPage: pageIndex + 1, resultsPerPage });
                },
                sortDescriptions: columns.find((column) => column.dataIndex === columnName) ? [new SortDescription(columnName, !sorter.isDescending)] : [],
            });
        };

        const getRoleGridConfig = (): Map<RoleName, any> => {
            const {
                regions,
                schools,
                schoolClasses,
                extraSchoolClasses,
                roleResources,
                totalCount,
                extraTotalCount,
                extraData,
            } = props;
            const mergedSchoolClasses = schoolClasses.length > 0 ? schoolClasses : extraSchoolClasses;
            const mergedTotalCount = totalCount || extraTotalCount;
            return new Map([
                [
                    RoleName.globalHead,
                    {
                        dataSource: regions,
                        totalCount: totalCount,
                        placeholder: DashboardLocale.SearchPlaceHolderRegion,
                        extraData: extraData,
                        exportFileName: "Regions.xlsx",
                        columns: RoleColumnSettings(RoleName.globalHead),
                    },
                ],
                [
                    RoleName.systemAdmin,
                    {
                        dataSource: regions,
                        totalCount: totalCount,
                        placeholder: DashboardLocale.SearchPlaceHolderRegion,
                        extraData: extraData,
                        exportFileName: "Regions.xlsx",
                        columns: RoleColumnSettings(RoleName.systemAdmin),
                    },
                ],
                [
                    RoleName.trainingAdmin,
                    {
                        dataSource: regions,
                        totalCount: totalCount,
                        placeholder: DashboardLocale.SearchPlaceHolderRegion,
                        extraData: extraData,
                        exportFileName: "Regions.xlsx",
                        columns: RoleColumnSettings(RoleName.trainingAdmin),
                    },
                ],
                [
                    RoleName.regionAdmin,
                    {
                        dataSource: schools,
                        totalCount: totalCount,
                        placeholder: DashboardLocale.SearchPlaceHolderSchool,
                        extraData: extraData,
                        roleResources: roleResources,
                        exportFileName: "Schools.xlsx",
                        columns: RoleColumnSettings(RoleName.regionAdmin, props),
                    },
                ],
                [
                    RoleName.accountManager,
                    {
                        dataSource: schoolClasses,
                        totalCount: totalCount,
                        placeholder: DashboardLocale.SearchPlaceHolderSchoolCampusClass,
                        extraData: extraData,
                        exportFileName: "SchoolClasses.xlsx",
                        columns: RoleColumnSettings(RoleName.accountManager),
                    },
                ],
                [
                    RoleName.trainer,
                    {
                        dataSource: schoolClasses,
                        totalCount: totalCount,
                        placeholder: DashboardLocale.SearchPlaceHolderSchoolCampusClass,
                        extraData: extraData,
                        exportFileName: "SchoolClasses.xlsx",
                        columns: RoleColumnSettings(RoleName.trainer),
                    },
                ],
                [
                    RoleName.schoolAdmin,
                    {
                        dataSource: mergedSchoolClasses,
                        totalCount: mergedTotalCount,
                        placeholder: DashboardLocale.SearchPlaceHolderSchoolCampusClass,
                        extraData: extraData,
                        exportFileName: "SchoolClasses.xlsx",
                        columns: RoleColumnSettings(RoleName.schoolAdmin),
                    },
                ],
                [
                    RoleName.campusAdmin,
                    {
                        dataSource: mergedSchoolClasses,
                        totalCount: mergedTotalCount,
                        placeholder: DashboardLocale.SearchPlaceHolderSchoolCampusClass,
                        extraData: extraData,
                        exportFileName: "SchoolClasses.xlsx",
                        columns: RoleColumnSettings(RoleName.campusAdmin),
                    },
                ],
                [
                    RoleName.teacher,
                    {
                        dataSource: mergedSchoolClasses,
                        totalCount: mergedTotalCount,
                        placeholder: DashboardLocale.SearchPlaceHolderSchoolCampusClass,
                        extraData: extraData,
                        exportFileName: "SchoolClasses.xlsx",
                        columns: RoleColumnSettings(RoleName.teacher),
                    },
                ],
            ]);
        };

        const showMessageByRole = (roleName, wijmoGrid) => {
            const {
                ownerRegions,
                ownerSchools,
                ownerCampuses,
                schools,
                schoolClasses,
                href,
                extraSchoolClasses,
                totalCount,
            } = props;
            switch (roleName) {
                case RoleName.regionAdmin:
                    if (ownerRegions.length === 0) {
                        return (
                            <div className="prompt">
                                {NoResourcesPage.getNoResourcePromptMessage(ResourceType.Region)}
                            </div>
                        );
                    } else if (ownerRegions.length > 0 && schools.length === 0 && filter === "") {
                        return (
                            <div className="prompt">
                                {NoResourcesPage.getSetupResourcePromptMessage(ResourceType.School, href)}
                            </div>
                        );
                    } else {
                        return wijmoGrid;
                    }
                case RoleName.trainer:
                    return wijmoGrid;
                case RoleName.teacher:
                    return schoolClasses.length === 0 &&
                        totalCount === 0 &&
                        extraSchoolClasses.length === 0 &&
                        filter === "" ? (
                        <div className="prompt">
                            {NoResourcesPage.getNoResourcePromptMessage(ResourceType.SchoolClass)}
                        </div>
                    ) : (
                        wijmoGrid
                    );
                case RoleName.accountManager:
                    if (
                        (ownerSchools.length === 0 && totalCount === 0) ||
                        (ownerSchools.filter((resource) => resource.resources.filter((s) => !s.disabled).length > 0)
                            .length === 0 && totalCount === 0)
                    ) {
                        return (
                            <div className="prompt">
                                {NoResourcesPage.getNoResourcePromptMessage(ResourceType.School)}
                            </div>
                        );
                    } else if (ownerSchools.length > 0 && totalCount === 0 && schoolClasses.length === 0 && filter === "") {
                        return (
                            <div className="prompt">
                                {NoResourcesPage.getSetupResourcePromptMessage(ResourceType.SchoolClass, href)}
                            </div>
                        );
                    } else {
                        return wijmoGrid;
                    }
                case RoleName.schoolAdmin:
                    if (
                        (ownerSchools.length === 0 && totalCount === 0) ||
                        (ownerSchools.length > 0 &&
                            totalCount === 0 &&
                            schoolClasses.length === 0 &&
                            extraSchoolClasses.length === 0 &&
                            filter === "")
                    ) {
                        return (
                            <div className="prompt">
                                {NoResourcesPage.getNoResourcePromptMessage(ResourceType.School)}
                            </div>
                        );
                    } else {
                        return wijmoGrid;
                    }
                case RoleName.campusAdmin:
                    if (
                        (ownerCampuses.length === 0 && totalCount === 0) ||
                        (ownerCampuses.length > 0 &&
                            totalCount === 0 &&
                            schoolClasses.length === 0 &&
                            extraSchoolClasses.length === 0 &&
                            filter === "")
                    ) {
                        return (
                            <div className="prompt">
                                {NoResourcesPage.getNoResourcePromptMessage(ResourceType.Campus)}
                            </div>
                        );
                    } else {
                        return wijmoGrid;
                    }
                default:
                    return wijmoGrid;
            }
        };

        const renderItem = (s, e, roleName) => {
            const flex = s;
            const columns = getRoleGridConfig().get(roleName).columns;
            if (e.panel.cellType == CellType.Cell) {
                const column = columns.find((column) => column.dataIndex == flex.columns[e.col].binding);
                if (column && column.render) {
                    const cellData = flex.rows[e.row].dataItem[column.dataIndex];
                    const dataItem = flex.rows[e.row].dataItem;
                    if (typeof cellData === "string" && dataItem && !dataItem.groupDescription) {
                        e.cell.innerHTML = column.render(
                            htmlEncodeByRegExp(cellData),
                            flex.rows[e.row].dataItem,
                            e.row
                        );
                    } else if (dataItem && !dataItem.groupDescription) {
                        e.cell.innerHTML = column.render(cellData, flex.rows[e.row].dataItem, e.row);
                    }
                }
                if (flex.rows[e.row] instanceof GroupRow) {
                    if (e.col <= flex.collectionView.groupDescriptions.length && e.cell.innerHTML.length) {
                        removeClass(e.cell, "wj-wrap");
                        if (e.cell.parentElement && e.cell.parentElement.parentElement) {
                            let width = e.cell.parentElement.parentElement.clientWidth;
                            setCss(e.cell, { width: width, zIndex: 100 });
                        }
                    }
                }
            }
            if (e.panel.cellType == CellType.ColumnHeader) {
                e.cell.innerHTML = `<div>${e.cell.innerHTML}</div>`;
            }
        };

        const getGridColumns = (roleName: RoleName) => {
            const columns = getRoleGridConfig().get(roleName).columns;
            return (
                columns &&
                columns.map((column, index) => {
                    const width = typeof column.width == "string" ? column.width.replace("%", "*") : column.width;
                    return (
                        <FlexGridColumn
                            key={index}
                            binding={column.dataIndex}
                            header={fmtMsg({ id: column.title as string })}
                            width={width}
                            minWidth={column.minWidth}
                            cssClass={column.className}
                            align={column.align}
                            allowSorting={typeof column.allowSorting === "boolean" ? column.allowSorting : true}
                            visible={typeof column.visible === "boolean" ? column.visible : true}
                            allowMerging={false}
                            allowDragging={column.allowGrouping}
                        ></FlexGridColumn>
                    );
                })
            );
        };

        const updateColumnHeaders = () => {
            let currentFlex = flex.get(activeTabKey);
            if (Array.isArray(currentFlex)) return;
            let columnLayout = JSON.parse(currentFlex.columnLayout);
            const columns = getRoleGridConfig().get(activeTabKey).columns;
            // update header language
            columnLayout.columns.forEach((col) => {
                Object.keys(columns).forEach((key) => {
                    if (columns[key].dataIndex === col.binding) {
                        col.header = fmtMsg({ id: columns[key].title as string });
                    }
                });
            });
            let newcolumnLayout = JSON.stringify(columnLayout);
            if (currentFlex.columnLayout != newcolumnLayout) {
                currentFlex.columnLayout = newcolumnLayout;
            }
        };

        const setFilterValue = (e: any, roleName: RoleName) => {
            const currentFilterValue = e.target.value.trim();
            if (currentFilterValue.length === 0) onFilterChange("", roleName);
            // update filter
            setFilter(e.target.value);
            fillterRef.current = e.target.value;
        };

        const onFilterChange = (value: any, roleName: RoleName) => {
            const gpVisible = groupPanelVisibility.get(roleName);
            if (gpVisible) {
                const currentFilterValue = value.trim().toLowerCase();
                view.get(roleName).filter = (item) => {
                    if (currentFilterValue.length === 0) return true;
                    return getFilterOnColumns.get(roleName).columns.some((element) => {
                        if (
                            item[element] &&
                            item[element]
                                .toString()
                                .toLowerCase()
                                .indexOf(currentFilterValue) > -1
                        ) {
                            return true;
                        }
                    });
                };
                view.get(roleName).onPageChanged();
            } else {
                updateNavInfo(roleName, { totalItemCount: 0, currentPage: 1, resultsPerPage });
                loadTabData(roleName, value);
            }
        };

        const onExportData = (fileName: string, roleName: RoleName) => {
            const gpVisible = groupPanelVisibility.get(roleName);
            if (gpVisible) {
                let currentFlex = flex.get(roleName);
                currentFlex.collectionView.pageSize = 0;
                FlexGridXlsxConverter.saveAsync(
                    currentFlex,
                    {
                        includeColumnHeaders: true,
                        includeCellStyles: false,
                        formatItem: exportDataFormat,
                    },
                    fileName
                );
                currentFlex.collectionView.pageSize = resultsPerPage;
                onPaginationChange(currentFlex.collectionView, navInfo.get(roleName).currentPage, roleName);
            } else {
                props.getData({
                    query: {
                        roleName,
                        filterText: filter,
                        ...tabsSorter.get(roleName),
                        ...getTabQueryParams(roleName, tabsListStatesStore.get(roleName).getState()),
                        isExportData: true,
                    },
                });
            }
        };

        const saveData2Excel = (roleName: RoleName, dataSource: any) => {
            const gridConfig = getRoleGridConfig().get(roleName);
            const currentFlex = flex.get(roleName);
            //const columns: any = RoleColumnSettings(roleName);
            Wijmo2ExcelHelper.export2Excel(
                dataSource,
                currentFlex.columns,
                gridConfig.exportFileName,
                { rows: 1 },
                onFormatCell,
                onSaved,
                onError
            );
        };

        const onSaved = (base64) => {
            const { setLoading } = props;
            setLoading && setLoading(false);
        };

        const onError = (reason) => {
            const { setLoading } = props;
            setLoading && setLoading(false);
        };

        const exportDataFormat = (args) => {
            excelExport(args);
        };

        const onPaginationChange = (currentView, page: number, roleName: RoleName) => {
            const gpVisible = groupPanelVisibility.get(roleName);
            if (gpVisible) {
                currentView.moveToPage(page - 1);
            } else {
                updateNavInfo(roleName, {
                    totalItemCount: navInfo.get(roleName).totalItemCount,
                    currentPage: page,
                    resultsPerPage,
                });
                loadTabData(roleName);
            }
        };

        const getNoResourcePage = () => {
            const { ownerCampuses, ownerRegions, ownerSchools, href } = props;
            switch (activeTabKey) {
                case RoleName.regionAdmin:
                    return (
                        <NoResourcesPage
                            roleName={RoleName.regionAdmin}
                            type={ResourceType.Region}
                            ownerResource={ownerRegions}
                            href={href}
                        />
                    );
                case RoleName.accountManager:
                    return (
                        <NoResourcesPage
                            roleName={RoleName.accountManager}
                            type={ResourceType.School}
                            ownerResource={ownerSchools}
                            href={href}
                        />
                    );
                case RoleName.trainer:
                    return <NoResourcesPage roleName={RoleName.trainer} />;
                case RoleName.schoolAdmin:
                    return (
                        <NoResourcesPage
                            roleName={RoleName.schoolAdmin}
                            type={ResourceType.School}
                            ownerResource={ownerSchools}
                            href={href}
                        />
                    );
                case RoleName.campusAdmin:
                    return (
                        <NoResourcesPage
                            roleName={RoleName.campusAdmin}
                            type={ResourceType.Campus}
                            ownerResource={ownerCampuses}
                            href={href}
                        />
                    );
                case RoleName.teacher:
                    return <NoResourcesPage roleName={RoleName.teacher} />;
            }
        };

        const onGroupPanelChanged = () => {
            let currentFlex = flex.get(activeTabKey);
            let currentView = currentFlex.collectionView;
            let newNavInfo = navInfo;
            let navInfo2Update = navInfo.get(activeTabKey);
            if (currentView && currentView.groups) {
                // update view range
                // hide columns that are in groupDescriptions
                let groupNames = [];
                currentView.groupDescriptions &&
                    currentView.groupDescriptions.forEach((groupDesc) => {
                        groupNames.push(groupDesc.propertyName);
                    });
                let columnLayout = JSON.parse(currentFlex.columnLayout);
                for (let i = 0; i < groupNames.length; i++) {
                    for (let j = 0; j < columnLayout.columns.length; j++) {
                        if (columnLayout.columns[j].binding == groupNames[i]) {
                            columnLayout.columns[j].visible = false;
                            // insert sort description if doesn't exist
                            let found = false;
                            currentView.sortDescriptions &&
                                currentView.sortDescriptions.forEach((sortDesc) => {
                                    if (sortDesc.property === groupNames[i]) found = true;
                                });
                            if (!found) {
                                currentView.sortDescriptions.push(new SortDescription(groupNames[i], true));
                            }
                        }
                    }
                }
                let newcolumnLayout = JSON.stringify(columnLayout);
                if (currentFlex.columnLayout != newcolumnLayout) {
                    currentFlex.columnLayout = newcolumnLayout;
                }
                if (currentView.pageSize > 0) {
                    currentView.pageSize = 0;
                    updateNavInfo(activeTabKey, {
                        totalItemCount: navInfo2Update.totalItemCount,
                        currentPage: 1,
                        resultsPerPage: navInfo2Update.totalItemCount,
                    });
                    setInGroupMode(true);
                }
            } else {
                currentView ? (currentView.pageSize = resultsPerPage) : null;
                updateNavInfo(activeTabKey, {
                    totalItemCount: navInfo2Update.totalItemCount,
                    currentPage: navInfo2Update.currentPage,
                    resultsPerPage,
                });
                // reset to default sorting
                let defaultSortKey = defaultSorting.get(activeTabKey).sortBy;
                if (inGroupMode) {
                    currentView.sortDescriptions.clear();
                    currentView.sortDescriptions.push(new SortDescription(defaultSortKey, true));
                }
                inGroupMode ? setInGroupMode(false) : null;
            }
        };

        const needRenderListStateRadios = (roleName: RoleName) => {
            switch (roleName) {
                case RoleName.trainer:
                case RoleName.schoolAdmin:
                case RoleName.campusAdmin:
                case RoleName.teacher:
                case RoleName.accountManager:
                    return true;
                default:
                    return false;
            }
        };

        const renderEmptyText = (roleName: RoleName, emptyText) => {
            switch (roleName) {
                case RoleName.trainer:
                case RoleName.schoolAdmin:
                case RoleName.campusAdmin:
                case RoleName.teacher:
                case RoleName.accountManager:
                    return (
                        <div className="ant-table-placeholder" key="emptyText">
                            {typeof emptyText === "function" ? emptyText() : emptyText}
                        </div>
                    );
                default:
                    return null;
            }
        };

        const onGridClick = (event) => {
            const dataset = event.target.dataset;
            if (dataset.openUnitPlanModal) {
                const { regionId, schoolId, campusId, classId } = dataset;
                setCurrentSchoolClass({ regionId, schoolId, campusId, classId });
            }
        }

        const closeUnitPlanModal = () => {
            setCurrentSchoolClass(null);
        }

        const onSubmitUnitPlan = (roleName: RoleName) => {
            closeUnitPlanModal();
            loadTabData(roleName);
        }

        const renderWijmoGrid = (roleName: RoleName, gridConfigs: Map<RoleName, any>) => {
            if (roleName == RoleName.trainer) {
                return <CoachDashboard
                    forceRefresh={props.reloadCoachData}
                    hideTitle = {true} />;
            }
            const gridConfig = gridConfigs.get(roleName);
            const searchPlaceHolderId = gridConfig.placeholder;
            const placeholder = GLGlobal.intl.formatMessage({
                id: searchPlaceHolderId ? searchPlaceHolderId : GLLocale.Search,
            });
            const currentView = view.get(roleName);
            const gpVisible = groupPanelVisibility.get(roleName);
            const currentNavInfo = navInfo.get(roleName);
            const gridClassName = classNames({
                "dashboard-role-grid": true,
                "dashboard-role-grid-without-group-panel": !gpVisible,
                "dashboard-role-grid-teacher": roleName == RoleName.teacher
            });
            const dataIsEmpty = isDataEmpty(roleName, gridConfig.dataSource);
            const currentListStore = tabsListStatesStore.get(roleName);
            const { totalCount, activeCount, futureCount } = props;
            const countainerClassName = classNames({
                "hideGrid" : dataIsEmpty,
                //"dashboard-teacher-container": roleName == RoleName.teacher
            });
            return (
                <>
                    {roleName == RoleName.teacher && <TeacherDashboard hideTitle = {true} />}
                    {dataIsEmpty && getNoResourcePage()}
                    <div className={countainerClassName}>
                        <div className={gridClassName}>
                            <Input.Search
                                placeholder={placeholder}
                                value={filter}
                                onChange={(e) => setFilterValue(e, roleName)}
                                onSearch={(value) => onFilterChange(value, roleName)}
                            />
                        </div>
                        {needRenderListStateRadios(roleName) && (
                            <div className="resource-list-states">
                                <ListStatesRadios
                                    showInActiveRadio={false}
                                    allCnt={totalCount}
                                    activeCnt={activeCount}
                                    futureCount={futureCount}
                                    value={currentListStore.getState()}
                                    onChange={(state) => {
                                        currentListStore.setState(state);
                                        updateNavInfo(roleName, {
                                            totalItemCount: navInfo.get(roleName).totalItemCount,
                                            currentPage: 1,
                                            resultsPerPage,
                                        });
                                        loadTabData(roleName, null, state);
                                    }}
                                />
                                <div className="dashboard-role-grid-right">
                                    {!gpVisible && (
                                        <div className="dashboard-role-grid-switch">
                                            <a
                                                onClick={(e) => {
                                                    let newGroupPanelVisibility = groupPanelVisibility;
                                                    groupPanelVisibility.set(roleName, !gpVisible);
                                                    setGroupPanelVisibility(newGroupPanelVisibility);
                                                    setNavInfo(new Map(tabsPagination));
                                                    setFilter("");
                                                    loadTabData(roleName);
                                                }}
                                            >
                                                {fmtMsg({ id: DashboardLocale.LandingTabGroupSwitch })}
                                            </a>
                                        </div>
                                    )}
                                    <div
                                        className="dashboard-role-grid-export"
                                        onClick={() => onExportData(gridConfig.exportFileName, roleName)}
                                    >
                                        <Icon type="download" />
                                        <span>{fmtMsg({ id: DashboardLocale.LandingTabExportText })}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {gpVisible && (
                            <GroupPanel
                                className="group-panel"
                                grid={flex.get(roleName)}
                                placeholder={fmtMsg({ id: DashboardLocale.LandingTabDragColumnsText })}
                                onRefreshed={() => onGroupPanelChanged()}
                            />
                        )}
                        <ReactFlexGrid
                            allowSorting={true}
                            autoGenerateColumns={false}
                            allowMerging={AllowMerging.Cells}
                            allowResizing={AllowResizing.None}
                            alternatingRowStep={0}
                            filterType="None"
                            frozenColumns={0}
                            initialized={(grid) => initGrid(grid, gridConfig.dataSource, roleName)}
                            frozenRows={0}
                            headersVisibility={HeadersVisibility.Column}
                            isReadOnly={true}
                            selectionMode={SelectionMode.ListBox}
                            formatItem={(s, e) => renderItem(s, e, roleName)}
                            itemsSource={currentView}
                            style={{ minHeight: currentNavInfo.totalItemCount > 0 ? "400px" : "unset" }}
                        >
                            {getGridColumns(roleName)}
                        </ReactFlexGrid>
                        {gridConfig.dataSource.length == 0 && renderEmptyText(roleName, <Empty />)}
                        {currentNavInfo.totalItemCount > 0 && (
                            <Pagination
                                className="pagination"
                                current={currentNavInfo.currentPage}
                                total={currentNavInfo.totalItemCount}
                                pageSize={currentNavInfo.resultsPerPage}
                                hideOnSinglePage={true}
                                showTotal={(total, range) =>
                                    fmtMsg(
                                        { id: DashboardLocale.LandingTabPagination },
                                        { "0": range[0], "1": range[1], "2": total }
                                    )
                                }
                                onChange={(page: number, pageSize?: number) =>
                                    onPaginationChange(currentView, page, roleName)
                                }
                            />
                        )}
                        <Modal
                            width={840}
                            visible={!!currentSchoolClass}
                            footer={null}
                            closable={false}
                            onCancel={closeUnitPlanModal}
                        >
                            {
                                !!currentSchoolClass &&
                                <UnitPlan
                                    regionId={currentSchoolClass.regionId}
                                    schoolId={currentSchoolClass.schoolId}
                                    campusId={currentSchoolClass.campusId}
                                    classId={currentSchoolClass.classId}
                                    isPopup={true}
                                    onCancel={closeUnitPlanModal}
                                    onSubmit={() => onSubmitUnitPlan(roleName)}
                                />
                            }
                        </Modal>
                    </div>
                </>
            );
        };

        const renderLandingTabs = (roleNames: RoleName[], gridConfigs: Map<RoleName, any>) => {
            const TabPane = Tabs.TabPane;
            return roleNames.length === 1 ? (
                showMessageByRole(roleNames[0], renderWijmoGrid(roleNames[0], gridConfigs))
            ) : (
                <Tabs onChange={onTabChanged}>
                    {roleNames.map((roleName) => {
                        return (
                            <TabPane tab={fmtMsg({ id: roleText.get(roleName) })} key={roleName}>
                                {renderWijmoGrid(roleName, gridConfigs)}
                            </TabPane>
                        );
                    })}
                </Tabs>
            );
        };
        return (
            <Spin spinning={props.loading}>
                <div className="dashboard-tabs">{renderLandingTabs(roleNames, getRoleGridConfig())}</div>
            </Spin>
        );
    })
);
