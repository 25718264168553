import React, { Component } from 'react';
import { GLGlobal, GLUtil } from 'gl-commonui';
import { PathConfig } from "@app/config/pathconfig";
import { GSAdminAction, GSSchoolAction } from '@app/util/index';
import { DashboardLocale, SchoolLocale } from '@app/locales/localeid';
import { ClassesModel, ClassesPropsModel } from '@app/service/class/model';
import { GLGridColumnProps } from '@app/components/gl-grid';
import { RoleGrid } from './role-grid';

const ColumnSortingMap: Map<string, string> = new Map([
    [ClassesPropsModel.curriculumTypeText, 'curriculumType'],
]);

export const SchoolCampusAdminGridDefaultSorter = {
    columnName: ClassesPropsModel.schoolName as string,
    columnSortName: ClassesPropsModel.schoolName as string,
    ascending: true
};

export class SchoolCampusAdminGrid extends RoleGrid<ClassesModel> {

    static defaultProps = {
        searchPlaceHoderId: DashboardLocale.SearchPlaceHolderSchoolCampusClass,
        defaultSorter: SchoolCampusAdminGridDefaultSorter
    };

    constructor(props, context) {
        super(props, context);
    }

    getColumns(): GLGridColumnProps<ClassesModel>[] {
        let columns: GLGridColumnProps<ClassesModel>[] = [
            {
                title: SchoolLocale.ClassesColumnSchool,
                dataIndex: ClassesPropsModel.schoolName,
                width: '20%',
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.Campuses)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Schools, { regionId: school.regionId, schoolId: school.schoolId })}" > <span>${text}</span> </a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                },
                className: 'navigation-col'
            },
            {
                title: SchoolLocale.ClassCampusSelect,
                dataIndex: ClassesPropsModel.campusName,
                width: '20%',
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.Classes)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Classes, { regionId: school.regionId, schoolId: school.schoolId, campusId: school.campusId })}" > <span>${text}</span> </a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                },
                className: 'navigation-col'
            },
            {
                title: SchoolLocale.ClassesColumnClass,
                dataIndex: ClassesPropsModel.schoolClassName,
                width: '20%',
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.StudentList)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Students, { regionId: school.regionId, schoolId: school.schoolId, campusId: school.campusId, classId: school.schoolClassId })}" > <span>${text}</span> </a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                },
                className: 'navigation-col'
            },
            {
                title: SchoolLocale.ClassesColumnAgeGrade,
                dataIndex: ClassesPropsModel.schoolClassAge,
                width: '8%',
            },
            {
                title: SchoolLocale.ClassCurriculumType,
                dataIndex: ClassesPropsModel.curriculumTypeText,
                width: '10%'
            },
            {
                title: SchoolLocale.ClassesColumnTeacher,
                dataIndex: ClassesPropsModel.teacherName,
                sorter: false,
                width: '8%'
            },
            {
                title: SchoolLocale.ClassesColumnStudentCount,
                dataIndex: ClassesPropsModel.studentCount,
                width: '8%'
            },
            {
                title: SchoolLocale.ClassesColumnUnit,
                dataIndex: ClassesPropsModel.currentUnit,
                width: '6%',
                align: 'right'
            }
        ];
        return columns;
    }

    getColumnSortingMap(): Map<string, string> {
        return ColumnSortingMap;
    }
}