import React, { Component } from 'react';
import { Input, Icon } from 'antd-min';
import { GLGrid, GLGridSorter, GLGridColumnProps, FlexGrid, CellType, GLGrid as GLGrid4Export, FlexGridXlsxConverter } from '@app/components/gl-grid';
import { GLGlobal, GLLocale, RoleName, PaginationParams } from 'gl-commonui';
import { fmtMsg } from '@app/util';
import { DashboardLocale } from '@app/locales/localeid';
import { ClassesPropsModel } from '@app/service/class/model';

export type RoleGridQueryParams = {
    filter?: string,
    sorter?: GLGridSorter,
    pagination?: PaginationParams
};

interface RoleGridProps<T> {
    searchPlaceHoderId?: string
    roleName: RoleName
    defaultSorter: GLGridSorter
    dataSource?: any[]
    dataSource4Export?: any[]
    roleResources?: any[]
    mergeDataKey?: string, 
    mergingColumns?: Map<string, number>    
    totalCount?: number
    extraData?: {allCnt: number, activeCnt: number, inActiveCnt: number}
    exportFileName?: string
    queryData?: (query) => void
    onQueryParamsChanged?: (roleName: RoleName, queryParams: RoleGridQueryParams) => void
    setLoading?: (loading) => void
}

export const DefaultPaginationParams = new PaginationParams(1, 20);

const SchoolCampusClassSortColumnBindings: Map<string, string[]> = new Map([
    [ClassesPropsModel.schoolName, [ClassesPropsModel.schoolName, ClassesPropsModel.campusName, ClassesPropsModel.schoolClassName]],
    [ClassesPropsModel.campusName, [ClassesPropsModel.campusName, ClassesPropsModel.schoolClassName]],
]);

const RoleGridSortBindings : Map<RoleName, Map<string, string[]>> = new Map([
    [RoleName.trainer, SchoolCampusClassSortColumnBindings],
    [RoleName.accountManager, SchoolCampusClassSortColumnBindings],
    [RoleName.schoolAdmin, SchoolCampusClassSortColumnBindings],
    [RoleName.campusAdmin, SchoolCampusClassSortColumnBindings],
    [RoleName.teacher, SchoolCampusClassSortColumnBindings],
]);

export class RoleGrid<T> extends Component<RoleGridProps<T>, {filterText: string, isExporting: boolean}> {
    private queryParams: RoleGridQueryParams;
    private columnSortingMap: Map<string, string>;
    private grid: FlexGrid;
    private grid4Export: FlexGrid;
    private exportQueryParams: any;

    constructor(props, context) {
        super(props, context);
        this.queryParams = {
            filter: '',
            sorter: props.defaultSorter,
            pagination: this.getDefaultPagination()
        };
        this.exportQueryParams = {...this.queryParams};
        this.state = { 
            filterText: '',
            isExporting: false
        };
        this.columnSortingMap = new Map<string, string>();
        this.getQueryParams = this.getQueryParams.bind(this);
        this.onQueryData = this.onQueryData.bind(this);
        this.onExportData = this.onExportData.bind(this);
        this.onLoadedRows = this.onLoadedRows.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.onError = this.onError.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onPagingChange = this.onPagingChange.bind(this);
        this.onColumnSorting = this.onColumnSorting.bind(this);
    }

    getColumns(props?): GLGridColumnProps<T>[] {
        throw 'not implementation';
    }

    getColumnSortingMap(): Map<string, string> {
        return new Map<string, string>();
    }

    getQueryParams(filter?) {
        const { roleName } = this.props;
        const sortMap = this.queryParams.sorter ? getRoleGridSortColumnMapBindings(roleName, this.queryParams.sorter.columnSortName, !this.queryParams.sorter.ascending) : null;
        return {
            roleName: roleName, 
            filterText: (filter ? filter : this.queryParams.filter).trim(), 
            sortBy: sortMap ? sortMap.columnNames : null,
            isDescending: sortMap ? sortMap.isDescending  : null
        };
    }

    getDefaultPagination() {
        return new PaginationParams(DefaultPaginationParams.current, DefaultPaginationParams.pageSize);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ('dataSource' in this.props && 'dataSource' in nextProps && this.props.dataSource != nextProps.dataSource && nextProps.dataSource) {
            this.onDataSourceChanged(this.grid, nextProps.dataSource);
        }
        // if ('dataSource4Export' in this.props && 'dataSource4Export' in nextProps && this.props.dataSource4Export != nextProps.dataSource4Export && nextProps.dataSource4Export) {
        //     this.setState({exportDataLoaded: true});
        // }
    }

    onDataSourceChanged(grid, dataSource: any[]) {  }

    onFormatItem(item) {
        if (item.panel.cellType == CellType.ColumnHeader) {
            item.xlsxCell.style.font = {bold: true};
        }
    }

    onLoadedRows() {  
        if (this.grid4Export && this.state.isExporting) {
            const {exportFileName} = this.props;
            FlexGridXlsxConverter.saveAsync(
                this.grid4Export, 
                { 
                    includeColumnHeaders: true, 
                    includeCellStyles: false,
                    formatItem: this.onFormatItem
                }, 
                exportFileName ? exportFileName : 'FlexGrid.xlsx',
                this.onSaved,
                this.onError
            );            
        }
    }

    onSaved(base64) {
        this.setState({isExporting: false});
        const {setLoading} = this.props;
        setLoading && setLoading(false);
    }

    onError(reason) {
        this.setState({isExporting: false});
        const {setLoading} = this.props;
        setLoading && setLoading(false);
    }

    onFilterChange(e) {
        const { roleName, onQueryParamsChanged } = this.props;
        const filter = e.target.value;
        this.queryParams.filter = filter;
        onQueryParamsChanged && onQueryParamsChanged(roleName, { ...this.queryParams })
        this.setState({
            filterText: filter
        });
    }

    onColumnSorting(sorter: GLGridSorter) {
        const { roleName, onQueryParamsChanged } = this.props;
        if (this.columnSortingMap.size == 0) {
            this.columnSortingMap = this.getColumnSortingMap();
        }
        const columnName = this.columnSortingMap.has(sorter.columnSortName) ? this.columnSortingMap.get(sorter.columnSortName) : sorter.columnSortName;
        this.queryParams.sorter = {...sorter, columnSortName: columnName };
        onQueryParamsChanged && onQueryParamsChanged(roleName, { ...this.queryParams })        
        this.onQueryData();
    }

    onPagingChange(pagination, filters, sorter) {
        const { roleName, onQueryParamsChanged } = this.props;
        this.queryParams.pagination.current = pagination.current ? pagination.current : pagination;
        onQueryParamsChanged && onQueryParamsChanged(roleName, { ...this.queryParams })
        this.onQueryData();
    }

    onQueryData() {
        const { queryData, roleName, onQueryParamsChanged } = this.props;
        onQueryParamsChanged && onQueryParamsChanged(roleName, { ...this.queryParams });
        this.exportQueryParams = {...this.getQueryParams()};
        queryData({query: {
            ...this.exportQueryParams,
            ...this.queryParams.pagination.toRequest()
        }});        
    }

    onExportData() {
        const { queryData } = this.props;
        const queryParams = this.exportQueryParams ? {...this.getQueryParams(), filterText: this.exportQueryParams.filter} : {...this.getQueryParams()};
        queryData({query: {
            ...queryParams,
            offset: 0,
            limit: null,
            isExportData: true
        }});
        this.setState({isExporting: true});
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.defaultSorter != nextProps.defaultSorter) {
            this.queryParams.sorter = nextProps.defaultSorter;
        }
    }

    render() {
        const { searchPlaceHoderId, roleName, dataSource, defaultSorter, queryData, totalCount, mergeDataKey, mergingColumns, dataSource4Export } = this.props;
        const placeholder = GLGlobal.intl.formatMessage({ id: searchPlaceHoderId? searchPlaceHoderId : GLLocale.Search });
        return (
            <div className='dashboard-role-grid'>
                <Input.Search
                    placeholder={placeholder}
                    value={this.queryParams.filter}
                    onChange={this.onFilterChange}
                    onSearch={value => {
                        queryData({query: {
                            ...this.getQueryParams(value.trim()), 
                            ...DefaultPaginationParams.toRequest()
                        }});
                        this.queryParams.pagination = this.getDefaultPagination();
                    }}
                />
                <div className='dashboard-role-grid-export' onClick={this.onExportData}>
                    <Icon type="download" /><span>{fmtMsg({id: DashboardLocale.LandingTabExportText})}</span>
                </div>
                <GLGrid
                    keyValue={roleName}
                    ref={(component)=> { if (component && component.grid) this.grid = component.grid; }}
                    dataSource = {dataSource}
                    defaultSorter = {defaultSorter}
                    columns = {this.getColumns(this.props)}
                    pagination = {{...this.queryParams.pagination, total: totalCount}}
                    onChange = {this.onPagingChange}
                    onColumnSorting = {this.onColumnSorting}
                    mergeDataKey = {mergeDataKey}
                    mergingColumns = {mergingColumns}
                />
                {this.state.isExporting &&
                <div className='dashboard-role-grid-export-grid'>
                    <GLGrid4Export
                        keyValue={roleName}
                        ref={(component)=> { if (component && component.grid) this.grid4Export = component.grid; }}
                        dataSource = {dataSource4Export}
                        defaultSorter = {defaultSorter}
                        columns = {this.getColumns(this.props)}
                        pagination = {false}
                        mergeDataKey = {mergeDataKey}
                        mergingColumns = {mergingColumns}
                        onLoadedRows = {this.onLoadedRows}
                    />
                </div>
                }
            </div>
        )
    }
}

export function getRoleGridSortColumnMapBindings(roleName, sortColumnName, isDescending): {columnNames: string[], isDescending: string[]} {
    const sortBindings = RoleGridSortBindings.get(roleName);
    const sortColumns = sortBindings && sortBindings.get(sortColumnName);
    if (!sortBindings || !sortColumns) {
        return {
            columnNames: sortColumnName,
            isDescending: isDescending
        }    
    }
    return {
        columnNames: sortColumns,
        isDescending: sortColumns.map(column => isDescending)
    }
}