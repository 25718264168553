import {GLGlobal, GLUtil, Role, RoleName} from "gl-commonui";
import {DashboardLocale, GSAdminLocale, SchoolLocale} from "@app/locales/localeid";
import {RegionModelPropNames} from "@app/service/admin/regions";
import {GSAdminAction, GSSchoolAction, SchoolClassDayName} from "@app/util";
import {PathConfig} from "@app/config/pathconfig";
import {SchoolModelPropNames} from "@app/service/schools";
import {ClassesPropsModel} from "@app/service/class";
import {XlsxFormatItemEventArgs} from "wijmo/wijmo.grid.xlsx";
import {IWorkbookCell, WorkbookFill} from "wijmo/wijmo.xlsx";
import {CellType, GroupRow} from "wijmo/wijmo.grid";
import {ColumnProps} from "antd/lib/table";
import {ListStatesStore} from "@app/components";

export const RoleColumnSettings = (roleName: RoleName, props: any = null) => {
    const formatMessage = GLGlobal.intl.formatMessage;
    if (roleName === RoleName.globalHead || roleName === RoleName.systemAdmin) {
        return [
            {
                title: GSAdminLocale.RegionsModelName,
                dataIndex: RegionModelPropNames.englishName,
                width: "25%",
                render: (text, region, index) => {
                    if (GLGlobal.isActionValid(GSAdminAction.ListRegion)) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Region,
                            { regionId: region.id }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                },
                className: "navigation-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: GSAdminLocale.RegionModelGSContentVersion,
                dataIndex: RegionModelPropNames.gsContentversion,
                width: "15%",
                allowGrouping: true,
                visible: true
            },
            {
                title: GSAdminLocale.RegionModelLSContentVersion,
                dataIndex: RegionModelPropNames.lsContentversion,
                width: "15%",
                allowGrouping: true,
                visible: true
            },
            {
                title: GSAdminLocale.RegionBillingDay,
                dataIndex: RegionModelPropNames.billingDay,
                width: "15%"
            },
            {
                title: GSAdminLocale.RegionListStudents,
                dataIndex: RegionModelPropNames.studentCount,
                width: "15%"
            },
            {
                title: GSAdminLocale.RegionListTeachers,
                dataIndex: RegionModelPropNames.teacherCount,
                width: "15%"
            }
        ];
    }
    if (roleName === RoleName.trainingAdmin) {
        return [
            {
                title: GSAdminLocale.RegionsModelName,
                dataIndex: RegionModelPropNames.englishName,
                width: "35%",
                render: (text, region, index) => {
                    if (GLGlobal.isActionValid(GSAdminAction.ListRegion)) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Region,
                            { regionId: region.id }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                },
                className: "navigation-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: GSAdminLocale.RegionModelGSContentVersion,
                dataIndex: RegionModelPropNames.gsContentversion,
                width: "20%",
                allowGrouping: true,
                visible: true
            },
            {
                title: GSAdminLocale.RegionModelLSContentVersion,
                dataIndex: RegionModelPropNames.lsContentversion,
                width: "20%",
                allowGrouping: true,
                visible: true
            },
            {
                title: GSAdminLocale.RegionListStudents,
                dataIndex: RegionModelPropNames.studentCount,
                width: "15%"
            },
            {
                title: GSAdminLocale.RegionListTeachers,
                dataIndex: RegionModelPropNames.teacherCount,
                width: "15%"
            }
        ];
    }
    if (roleName === RoleName.regionAdmin) {
        const regionResources =
            props.roleResources &&
            props.roleResources.find(
                roleResource => roleResource.role == Role.RegionAdmin
            );
        const hasRegionColumns =
            "roleResources" in props &&
            regionResources &&
            regionResources.resource.length > 1;
        return [
            {
                title: GSAdminLocale.RegionsModelName,
                dataIndex: SchoolModelPropNames.regionEnglishName,
                width: "16%",
                visible: hasRegionColumns,
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSAdminAction.ListRegion)) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Region,
                            { regionId: school.regionId }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                },
                className: "navigation-col",
                allowGrouping: true
            },
            {
                title: GSAdminLocale.SchoolModelName,
                dataIndex: SchoolModelPropNames.name,
                width: "16%",
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSAdminAction.ListSchool)) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Schools,
                            { regionId: school.regionId, schoolId: school.id }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                },
                className: "navigation-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: GSAdminLocale.SchoolModelCode,
                dataIndex: SchoolModelPropNames.gsNumber,
                width: "10%",
                allowGrouping: true,
                visible: true
            },
            {
                title: GSAdminLocale.SchoolModelSubscriptionType,
                dataIndex: SchoolModelPropNames.subscriptionTypeText,
                width: "13%",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassesColumnTrainerName,
                dataIndex: SchoolModelPropNames.trainerName,
                allowSorting: false,
                width: "8%",
                render: (text, school, index) => {
                    if (school.hasMultipleTrainer) {
                        return `<span>${text}</span>
                         <a title = ${formatMessage({
                            id: GSAdminLocale.HomeShowMore
                        })} href="${GLUtil.pathStringify(
                            PathConfig.SchoolTrainers,
                            { regionId: school.regionId, schoolId: school.id }
                        )}"> +</a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                }
            },
            {
                title: SchoolLocale.ClassesColumnStudentCount,
                dataIndex: SchoolModelPropNames.studentCount,
                width: "8%"
            },
            {
                title: GSAdminLocale.SchoolModelMaxUnitLS,
                dataIndex: SchoolModelPropNames.maxUnitLS,
                width: "13%"
            },
            {
                title: GSAdminLocale.SchoolModelMaxUnitGS,
                dataIndex: SchoolModelPropNames.maxUnitGS,
                width: "11%"
            },
            {
                title: GSAdminLocale.SchoolModelLittleSEEDLicense,
                dataIndex: SchoolModelPropNames.littleSeedLicense,
                width: "13%"
            }
        ];
    }
    if (
        roleName === RoleName.accountManager ||
        roleName === RoleName.schoolAdmin ||
        roleName === RoleName.campusAdmin
    ) {
        const hasUnitPlanModal = roleName === RoleName.schoolAdmin || RoleName.campusAdmin;
        return [
            {
                title: SchoolLocale.ClassesColumnSchool,
                dataIndex: ClassesPropsModel.schoolName,
                width: "20%",
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.Campuses) && school.schoolId) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Schools,
                            {
                                regionId: school.regionId,
                                schoolId: school.schoolId
                            }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text? text : ''}</span>`;
                    }
                },
                className: "navigation-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassCampusSelect,
                dataIndex: ClassesPropsModel.campusName,
                width: "20%",
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.Classes) && school.campusId) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Classes,
                            {
                                regionId: school.regionId,
                                schoolId: school.schoolId,
                                campusId: school.campusId
                            }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text? text : ''}</span>`;
                    }
                },
                className: "navigation-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassesColumnClass,
                dataIndex: ClassesPropsModel.schoolClassName,
                width: "20%",
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.StudentList) && school.schoolClassId) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Students,
                            {
                                regionId: school.regionId,
                                schoolId: school.schoolId,
                                campusId: school.campusId,
                                classId: school.schoolClassId
                            }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text? text : ''}</span>`;
                    }
                },
                className: "navigation-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassesColumnAgeGrade,
                dataIndex: ClassesPropsModel.schoolClassAge,
                width: "8%"
            },
            {
                title: SchoolLocale.ClassCurriculumType,
                dataIndex: ClassesPropsModel.curriculumTypeText,
                width: "10%",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassesColumnTeacher,
                dataIndex: ClassesPropsModel.teacherName,
                allowSorting: false,
                width: "8%",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassesColumnStudentCount,
                dataIndex: ClassesPropsModel.studentCount,
                width: "8%"
            },
            {
                title: SchoolLocale.ClassesColumnUnit,
                dataIndex: ClassesPropsModel.currentUnit,
                width: "6%",
                align: "right",
                className: hasUnitPlanModal ? "navigation-col" : undefined,
                render: hasUnitPlanModal ? (text, school, index) => {
                    if (!text) {
                        return "";
                    }
                    return `<a
                                href="javascript:void(0)"
                                data-region-id="${school.regionId}"
                                data-school-id="${school.schoolId}"
                                data-campus-id="${school.campusId}"
                                data-class-id="${school.schoolClassId}"
                                data-open-unit-plan-modal="${true}"
                                >${text}</a>`;
                } : undefined,
            }
        ];
    }
    if (roleName === RoleName.trainer) {
        return [
            {
                title: SchoolLocale.ClassesColumnSchool,
                dataIndex: ClassesPropsModel.schoolName,
                width: "10%",
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.Campuses)) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Schools,
                            {
                                regionId: school.regionId,
                                schoolId: school.schoolId
                            }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                },
                className: "navigation-col merged-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassCampusSelect,
                dataIndex: ClassesPropsModel.campusName,
                width: "10%",
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.Classes)) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Classes,
                            {
                                regionId: school.regionId,
                                schoolId: school.schoolId,
                                campusId: school.campusId
                            }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                },
                className: "navigation-col merged-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassesColumnClass,
                dataIndex: ClassesPropsModel.schoolClassName,
                width: "10%",
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.StudentList)) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Students,
                            {
                                regionId: school.regionId,
                                schoolId: school.schoolId,
                                campusId: school.campusId,
                                classId: school.schoolClassId
                            }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                },
                className: "navigation-col merged-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassCurriculumType,
                dataIndex: ClassesPropsModel.curriculumTypeText,
                width: "10%",
                className: "merged-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassesColumnTeacher,
                dataIndex: ClassesPropsModel.teacherName,
                allowSorting: false,
                width: "8%",
                className: "merged-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassesColumnAgeGrade,
                dataIndex: ClassesPropsModel.age,
                width: "9%",
                className: "merged-col"
            },
            {
                title: SchoolLocale.ClassesColumnStudentCount,
                dataIndex: ClassesPropsModel.studentCount,
                align: "left",
                width: "8%",
                className: "merged-col"
            },
            {
                title: SchoolLocale.ClassesColumnUnit,
                dataIndex: ClassesPropsModel.currentUnit,
                align: "left",
                width: "4%",
                className: "merged-col"
            },
            {
                title: SchoolLocale.ClassesColumnDaysTSI,
                dataIndex: ClassesPropsModel.tsiLessonsPerWeek,
                width: "10%",
                className: "merged-col",
                align: "left",
            },
            {
                title: SchoolLocale.ClassesColumnDurationTSI,
                dataIndex: ClassesPropsModel.tsiTimePerWeek,
                width: "10%",
                className: "merged-col",
                align: "left",
            },
        ];
    }
    if (roleName === RoleName.teacher) {
        return [
            {
                title: SchoolLocale.ClassesColumnSchool,
                dataIndex: ClassesPropsModel.schoolName,
                width: "15%",
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.Campuses) && school.schoolId) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Schools,
                            {
                                regionId: school.regionId,
                                schoolId: school.schoolId
                            }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text? text : ''}</span>`;
                    }
                },
                className: "navigation-col merged-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassCampusSelect,
                dataIndex: ClassesPropsModel.campusName,
                width: "13%",
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.Classes) && school.campusId) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Classes,
                            {
                                regionId: school.regionId,
                                schoolId: school.schoolId,
                                campusId: school.campusId
                            }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text? text : ''}</span>`;
                    }
                },
                className: "navigation-col merged-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassesColumnClass,
                dataIndex: ClassesPropsModel.schoolClassName,
                width: "12%",
                className: "navigation-col merged-col",
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.StudentList) && school.schoolClassId) {
                        return `<a href="${GLUtil.pathStringify(
                            PathConfig.Students,
                            {
                                regionId: school.regionId,
                                schoolId: school.schoolId,
                                campusId: school.campusId,
                                classId: school.schoolClassId
                            }
                        )}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text? text : ''}</span>`;
                    }
                },
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassCurriculumType,
                dataIndex: ClassesPropsModel.curriculumTypeText,
                width: "10%",
                className: "merged-col",
                allowGrouping: true,
                visible: true
            },
            {
                title: SchoolLocale.ClassesColumnAgeGrade,
                dataIndex: ClassesPropsModel.age,
                width: "10%",
                className: "merged-col"
            },
            {
                title: SchoolLocale.ClassesColumnStudentCount,
                dataIndex: ClassesPropsModel.studentCount,
                align: "left",
                width: "8%",
                className: "merged-col"
            },
            {
                title: SchoolLocale.ClassesColumnUnit,
                dataIndex: ClassesPropsModel.currentUnit,
                align: "left",
                width: "4%",
                className: "merged-col navigation-col",
                render: (text, school, index) => {
                    if (!text) {
                        return "";
                    }
                    return `<a
                                href="javascript:void(0)"
                                data-region-id="${school.regionId}"
                                data-school-id="${school.schoolId}"
                                data-campus-id="${school.campusId}"
                                data-class-id="${school.schoolClassId}"
                                data-open-unit-plan-modal="${true}"
                                >${text}</a>`;
                }
            },
            {
                title: SchoolLocale.ClassesColumnDaysTSI,
                dataIndex: ClassesPropsModel.tsiLessonsPerWeek,
                width: "10%",
                className: "merged-col",
                align: "left",
            },
            {
                title: SchoolLocale.ClassesColumnDurationTSI,
                dataIndex: ClassesPropsModel.tsiTimePerWeek,
                width: "10%",
                className: "merged-col",
                align: "left",
            }
        ];
    }
};

export const excelExport = (args: XlsxFormatItemEventArgs) => {
    let cell: IWorkbookCell = args.xlsxCell;
    if (args.panel.cellType === CellType.ColumnHeader) {
        cell.style.font = { bold: true };
    }
    if (args.panel.rows[args.row] instanceof GroupRow && cell.value) {
        cell.colSpan = 10;
        let color = new WorkbookFill();
        color.color = "#f4d9d9";
        cell.style.fill = color;
    }
    if (args.panel.cellType === CellType.Cell) {
        let record = args.panel.rows[args.row].dataItem;

        if (
            args.panel.columns[args.col].binding === ClassesPropsModel.tsi_rep
        ) {
            if (record.tsiDuration && record.repDuration) {
                cell.value = "TSI | REP";
            } else if (record.tsiDuration) {
                cell.value = "TSI";
            } else if (record.repDuration) {
                cell.value = "REP";
            }
        } else if (
            args.panel.columns[args.col].binding === ClassesPropsModel.duration
        ) {
            if (record.tsiDuration && record.repDuration) {
                cell.value = `${record.tsiDuration} | ${record.repDuration}`;
            } else if (record.tsiDuration) {
                cell.value = `${record.tsiDuration}${
                    record.repDuration ? " | -" : ""
                    }`;
            } else if (record.repDuration) {
                cell.value = `${record.tsiDuration ? "- | " : ""}${
                    record.repDuration
                    }`;
            }
        } else if (
            args.panel.columns[args.col].binding === ClassesPropsModel.count
        ) {
            if (record.tsiCount && record.repCount) {
                cell.value = `${record.tsiCount} | ${record.repCount}`;
            } else if (record.tsiCount) {
                cell.value = `${record.tsiCount}${
                    record.repDuration ? " | -" : ""
                    }`;
            } else if (record.repCount) {
                cell.value = `${record.tsiDuration ? "- | " : ""}${
                    record.repCount
                    }`;
            }
        } else if (
            args.panel.columns[args.col].binding === ClassesPropsModel.days
        ) {
            cell.colSpan = 2;
            if (record.tsiDays && record.repDays) {
                cell.value = `${getClassDays(record.tsiDays)} | ${getClassDays(
                    record.repDays
                )}`;
            } else if (record.tsiDays) {
                cell.value = `${getClassDays(record.tsiDays)}${
                    record.repDuration ? " | -" : ""
                    }`;
            } else if (record.repDays) {
                cell.value = `${record.tsiDuration ? "- | " : ""}${getClassDays(
                    record.repDays
                )}`;
            }
        }
    }
};

export const onFormatCell = (dataItem, column: ColumnProps<any>, cellData, cell) => {
    if (column.dataIndex === ClassesPropsModel.tsi_rep) {
        if (dataItem.tsiDuration && dataItem.repDuration) {
            return "TSI | REP";
        } else if (dataItem.tsiDuration) {
            return "TSI";
        } else if (dataItem.repDuration) {
            return "REP";
        }
    } else if (column.dataIndex === ClassesPropsModel.duration) {
        if (dataItem.tsiDuration && dataItem.repDuration) {
            return `${dataItem.tsiDuration} | ${dataItem.repDuration}`;
        } else if (dataItem.tsiDuration) {
            return `${dataItem.tsiDuration}${
                dataItem.repDuration ? " | -" : ""
                }`;
        } else if (dataItem.repDuration) {
            return `${dataItem.tsiDuration ? "- | " : ""}${
                dataItem.repDuration
                }`;
        }
    } else if (column.dataIndex === ClassesPropsModel.count) {
        if (dataItem.tsiCount && dataItem.repCount) {
            return `${dataItem.tsiCount} | ${dataItem.repCount}`;
        } else if (dataItem.tsiCount) {
            return `${dataItem.tsiCount}${
                dataItem.repDuration ? " | -" : ""
                }`;
        } else if (dataItem.repCount) {
            return `${dataItem.tsiDuration ? "- | " : ""}${
                dataItem.repCount
                }`;
        }
    } else if (column.dataIndex === ClassesPropsModel.days) {
        cell.colSpan = 2;
        if (dataItem.tsiDays && dataItem.repDays) {
            return `${getClassDays(dataItem.tsiDays)} | ${getClassDays(
                dataItem.repDays
            )}`;
        } else if (dataItem.tsiDays) {
            return `${getClassDays(dataItem.tsiDays)}${
                dataItem.repDuration ? " | -" : ""
                }`;
        } else if (dataItem.repDays) {
            return `${dataItem.tsiDuration ? "- | " : ""}${getClassDays(
                dataItem.repDays
            )}`;
        }
    } else {
        return cellData;
    }
}

export const tabsViews: Map<RoleName, any> = new Map([
    [RoleName.globalHead, []],
    [RoleName.systemAdmin, []],
    [RoleName.trainingAdmin, []],
    [RoleName.regionAdmin, []],
    [RoleName.accountManager, []],
    [RoleName.trainer, []],
    [RoleName.schoolAdmin, []],
    [RoleName.campusAdmin, []],
    [RoleName.teacher, []]
]);

export const tabsFlex: Map<RoleName, any> = new Map([
    [RoleName.globalHead, []],
    [RoleName.systemAdmin, []],
    [RoleName.trainingAdmin, []],
    [RoleName.regionAdmin, []],
    [RoleName.accountManager, []],
    [RoleName.trainer, []],
    [RoleName.schoolAdmin, []],
    [RoleName.campusAdmin, []],
    [RoleName.teacher, []]
]);

export const tabsGroupPanelVisibility: Map<RoleName, boolean> = new Map([
    [RoleName.globalHead, false],
    [RoleName.systemAdmin, false],
    [RoleName.trainingAdmin, false],
    [RoleName.regionAdmin, false],
    [RoleName.accountManager, false],
    [RoleName.trainer, false],
    [RoleName.schoolAdmin, false],
    [RoleName.campusAdmin, false],
    [RoleName.teacher, false]
]);

export const tabsSorter: Map<RoleName, any> = new Map([
    [RoleName.globalHead, {sortBy: RegionModelPropNames.regionEnglishName, isDescending: false}],
    [RoleName.systemAdmin, {sortBy: RegionModelPropNames.regionEnglishName, isDescending: false}],
    [RoleName.trainingAdmin, {sortBy: RegionModelPropNames.regionEnglishName, isDescending: false}],
    [RoleName.regionAdmin, {sortBy: SchoolModelPropNames.regionEnglishName, isDescending: false}],
    [RoleName.accountManager, {sortBy: ClassesPropsModel.schoolName, isDescending: false}],
    [RoleName.trainer, {sortBy: ClassesPropsModel.schoolName, isDescending: false}],
    [RoleName.schoolAdmin, {sortBy: ClassesPropsModel.schoolName, isDescending: false}],
    [RoleName.campusAdmin, {sortBy: ClassesPropsModel.schoolName, isDescending: false}],
    [RoleName.teacher, {sortBy: ClassesPropsModel.schoolName, isDescending: false}]
]);

export const uiColumn2SortColumnMap: Map<string, string> = new Map<string, string>([
    [RegionModelPropNames.englishName, RegionModelPropNames.regionEnglishName],
    [RegionModelPropNames.gsContentversion, RegionModelPropNames.gsVersion],
    [RegionModelPropNames.lsContentversion, RegionModelPropNames.lsVersion],
    [SchoolModelPropNames.name, SchoolModelPropNames.schoolName],
    [SchoolModelPropNames.subscriptionTypeText, SchoolModelPropNames.subscriptionType],
    [SchoolModelPropNames.trainerName, SchoolModelPropNames.trainerId],
    [SchoolModelPropNames.digitalLicense, SchoolModelPropNames.digitalLicenseCount],
    [SchoolModelPropNames.textbookLicense, SchoolModelPropNames.textbookLicenseCount],
    [SchoolModelPropNames.littleSeedLicense, SchoolModelPropNames.littleSEEDCount],
    [ClassesPropsModel.curriculumTypeText, ClassesPropsModel.curriculumType],
    [ClassesPropsModel.teacherName, ClassesPropsModel.teacherId],
]);

export const sortColumn2UIColumnMap: Map<string, string> = new Map<string, string>([
    [RegionModelPropNames.regionEnglishName, RegionModelPropNames.englishName],
    [RegionModelPropNames.gsVersion, RegionModelPropNames.gsContentversion],
    [RegionModelPropNames.lsVersion, RegionModelPropNames.lsContentversion],
    [SchoolModelPropNames.schoolName, SchoolModelPropNames.name],
    [SchoolModelPropNames.subscriptionType, SchoolModelPropNames.subscriptionTypeText],
    [SchoolModelPropNames.trainerId, SchoolModelPropNames.trainerName],
    [SchoolModelPropNames.digitalLicenseCount, SchoolModelPropNames.digitalLicense],
    [SchoolModelPropNames.textbookLicenseCount, SchoolModelPropNames.textbookLicense],
    [SchoolModelPropNames.littleSEEDCount, SchoolModelPropNames.littleSeedLicense],
    [ClassesPropsModel.curriculumType, ClassesPropsModel.curriculumTypeText],
    [ClassesPropsModel.teacherId, ClassesPropsModel.teacherName],
]);

export const tabsPagination: Map<RoleName, any> = new Map([
    [RoleName.globalHead, {totalItemCount: 0, currentPage: 1, resultsPerPage: 0}],
    [RoleName.systemAdmin, {totalItemCount: 0, currentPage: 1, resultsPerPage: 0}],
    [RoleName.trainingAdmin, {totalItemCount: 0, currentPage: 1, resultsPerPage: 0}],
    [RoleName.regionAdmin, {totalItemCount: 0, currentPage: 1, resultsPerPage: 0}],
    [RoleName.accountManager, {totalItemCount: 0, currentPage: 1, resultsPerPage: 0}],
    [RoleName.trainer, {totalItemCount: 0, currentPage: 1, resultsPerPage: 0}],
    [RoleName.schoolAdmin, {totalItemCount: 0, currentPage: 1, resultsPerPage: 0}],
    [RoleName.campusAdmin, {totalItemCount: 0, currentPage: 1, resultsPerPage: 0}],
    [RoleName.teacher, {totalItemCount: 0, currentPage: 1, resultsPerPage: 0}]
]);

export const getFilterOnColumns: Map<RoleName, any> = new Map([
    [RoleName.globalHead, { columns: [RegionModelPropNames.englishName] }],
    [RoleName.systemAdmin, { columns: [RegionModelPropNames.englishName] }],
    [RoleName.trainingAdmin, { columns: [RegionModelPropNames.englishName] }],
    [
        RoleName.regionAdmin,
        { columns: [SchoolModelPropNames.name, SchoolModelPropNames.gsNumber] }
    ],
    [
        RoleName.accountManager,
        {
            columns: [
                ClassesPropsModel.schoolName,
                ClassesPropsModel.campusName,
                ClassesPropsModel.schoolClassName
            ]
        }
    ],
    [
        RoleName.trainer,
        {
            columns: [
                ClassesPropsModel.schoolName,
                ClassesPropsModel.campusName,
                ClassesPropsModel.schoolClassName
            ]
        }
    ],
    [
        RoleName.schoolAdmin,
        {
            columns: [
                ClassesPropsModel.schoolName,
                ClassesPropsModel.campusName,
                ClassesPropsModel.schoolClassName
            ]
        }
    ],
    [
        RoleName.campusAdmin,
        {
            columns: [
                ClassesPropsModel.schoolName,
                ClassesPropsModel.campusName,
                ClassesPropsModel.schoolClassName
            ]
        }
    ],
    [
        RoleName.teacher,
        {
            columns: [
                ClassesPropsModel.schoolName,
                ClassesPropsModel.campusName,
                ClassesPropsModel.schoolClassName
            ]
        }
    ]
]);

export const defaultSorting: Map<RoleName, any> = new Map([
    [RoleName.globalHead, { sortBy: RegionModelPropNames.englishName }],
    [RoleName.systemAdmin, { sortBy: RegionModelPropNames.englishName }],
    [RoleName.trainingAdmin, { sortBy: RegionModelPropNames.englishName }],
    [RoleName.regionAdmin, { sortBy: SchoolModelPropNames.regionEnglishName }],
    [RoleName.accountManager, { sortBy: ClassesPropsModel.schoolName }],
    [RoleName.trainer, { sortBy: ClassesPropsModel.schoolName }],
    [RoleName.schoolAdmin, { sortBy: ClassesPropsModel.schoolName }],
    [RoleName.campusAdmin, { sortBy: ClassesPropsModel.schoolName }],
    [RoleName.teacher, { sortBy: ClassesPropsModel.schoolName }]
]);

export const roleText = new Map([
    [RoleName.globalHead, DashboardLocale.LandingTabGlobalHeadText],
    [RoleName.systemAdmin, DashboardLocale.LandingTabSystemAdminText],
    [RoleName.trainingAdmin, DashboardLocale.LandingTabTrainingAdminText],
    [RoleName.regionAdmin, DashboardLocale.LandingTabRegionAdminText],
    [RoleName.accountManager, DashboardLocale.LandingTabAccountManagerText],
    [RoleName.trainer, DashboardLocale.LandingTabTrainerAdminText],
    [RoleName.trainingManager, DashboardLocale.LandingTabTrainingManagerText],
    [RoleName.schoolAdmin, DashboardLocale.LandingTabSchoolAdminText],
    [RoleName.campusAdmin, DashboardLocale.LandingTabCampusAdminText],
    [RoleName.teacher, DashboardLocale.LandingTabTeacherText]
]);

export const ClassMergedColumns: Map<string, number> = new Map([
    [ClassesPropsModel.schoolName, 0],
    [ClassesPropsModel.campusName, 1],
    [ClassesPropsModel.schoolClassName, 2],
    [ClassesPropsModel.curriculumTypeText, 3],
    [ClassesPropsModel.teacherName, 4],
    [ClassesPropsModel.age, 5],
    [ClassesPropsModel.studentCount, 6],
    [ClassesPropsModel.currentUnit, 7]
]);

const landingResource = 'landing-resource-list-states';
export const tabsListStatesStore: Map<RoleName, ListStatesStore> = new Map([
    [RoleName.globalHead, new ListStatesStore(`${RoleName.globalHead}-${landingResource}`, RoleName.globalHead)],
    [RoleName.systemAdmin, new ListStatesStore(`${RoleName.systemAdmin}-${landingResource}`, RoleName.systemAdmin)],
    [RoleName.trainingAdmin, new ListStatesStore(`${RoleName.trainingAdmin}-${landingResource}`, RoleName.trainingAdmin)],
    [RoleName.regionAdmin, new ListStatesStore(`${RoleName.regionAdmin}-${landingResource}`, RoleName.regionAdmin)],
    [RoleName.accountManager, new ListStatesStore(`${RoleName.accountManager}-${landingResource}`, RoleName.accountManager)],
    [RoleName.trainer, new ListStatesStore(`${RoleName.trainer}-${landingResource}`, RoleName.trainer)],
    [RoleName.schoolAdmin, new ListStatesStore(`${RoleName.schoolAdmin}-${landingResource}`, RoleName.schoolAdmin)],
    [RoleName.campusAdmin, new ListStatesStore(`${RoleName.campusAdmin}-${landingResource}`, RoleName.campusAdmin)],
    [RoleName.teacher, new ListStatesStore(`${RoleName.teacher}-${landingResource}`, RoleName.teacher)]
]);

function getClassDays(days: string) {
    return days
        ? days
            .split("/")
            .map(day =>
                GLGlobal.intl.formatMessage({ id: SchoolClassDayName[day] })
            )
            .join("/")
        : '';
}
