import React, { Component } from "react";
import { RoleName, ResourceType } from "gl-commonui";
import { DashboardLocale } from "@app/locales/localeid";
import { fmtMsg, getHref } from "@app/util";

interface NoResourcesProps {
    roleName?: string
    type?: number
    href?: string
    ownerResource?: any[]
}

interface NoResourcesStates {
    message?: any
}

const NoResourceMessages: Map<ResourceType, string> = new Map ([
    [ResourceType.Region, DashboardLocale.LandingTabNoRegionResource],
    [ResourceType.School, DashboardLocale.LandingTabNoSchoolResource],
    [ResourceType.Campus, DashboardLocale.LandingTabNoCampusResource],
    [ResourceType.SchoolClass, DashboardLocale.LandingTabNoClassResource],
]);

const NoResourceSetupMessages: Map<ResourceType, string> = new Map ([
    [ResourceType.Region, DashboardLocale.LandingTabRegionSetupResource],
    [ResourceType.School, DashboardLocale.LandingTabSchoolSetupResource],
    [ResourceType.Campus, DashboardLocale.LandingTabCampusSetupResource],
    [ResourceType.SchoolClass, DashboardLocale.LandingTabClassSetupResource],
]);

export class NoResourcesPage extends Component<NoResourcesProps, NoResourcesStates> {
    constructor(props, context) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps() {
        this.renderContent();
    }

    static getNoResourcePromptMessage(resource: ResourceType): string {
        return fmtMsg({id: NoResourceMessages.get(resource)});
    }

    static getSetupResourcePromptMessage(resource: ResourceType, href: string): JSX.Element {
        const fmtMessage = fmtMsg({id: NoResourceSetupMessages.get(resource)}, { link: "{link}" });
        const textArr = fmtMessage && fmtMessage.split("{link}");
        return (
            <React.Fragment>
                {textArr[0]}<a href={href}>{textArr[1]}</a>{textArr[2]}
            </React.Fragment>
        )
    }

    renderContent() {
        const { roleName, type, ownerResource, href } = this.props;
        switch(roleName) {
            case RoleName.systemAdmin:
            case RoleName.globalHead:
            case RoleName.trainingAdmin:
                break;
            case RoleName.regionAdmin:
                if (ownerResource.length > 0) {
                    this.setState({ message: NoResourcesPage.getSetupResourcePromptMessage(ResourceType.School, href) });
                } else {
                    this.setState({ message: NoResourcesPage.getNoResourcePromptMessage(ResourceType.Region) });
                }
                break;
            case RoleName.trainer:
            case RoleName.teacher:
                this.setState({ message: NoResourcesPage.getNoResourcePromptMessage(ResourceType.SchoolClass) });
                break;
            case RoleName.accountManager:
                if (ownerResource.length > 0 && ownerResource.filter(resource => resource.resources.filter(s=> !s.disabled).length > 0).length > 0) {
                    this.setState({ message: NoResourcesPage.getSetupResourcePromptMessage(ResourceType.SchoolClass, href) });
                } else {
                    this.setState({ message: NoResourcesPage.getNoResourcePromptMessage(ResourceType.School) });
                }
                break;
            case RoleName.schoolAdmin:
                this.setState({ message: NoResourcesPage.getNoResourcePromptMessage(ResourceType.School) });
                break;
            case RoleName.campusAdmin:
                this.setState({ message: NoResourcesPage.getNoResourcePromptMessage(ResourceType.Campus) });
                break;
        }
    }
    render() {
        return <div>{this.state.message}</div>;
    }
}