import React, { Component } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { GLGlobal, GLUtil } from 'gl-commonui';
import { PathConfig } from "@app/config/pathconfig";
import { GSSchoolAction, SchoolClassDayName } from '@app/util/index';
import { DashboardLocale, SchoolLocale } from '@app/locales/localeid';
import { ClassesModel, ClassesPropsModel } from '@app/service/class/model';
import { GLGridColumnProps } from '@app/components/gl-grid';
import { RoleGrid } from './role-grid';

const ClassMergedColumns: Map<string, number> = new Map([
    [ClassesPropsModel.schoolName, 0],
    [ClassesPropsModel.campusName, 1],
    [ClassesPropsModel.schoolClassName, 2],
    [ClassesPropsModel.curriculumTypeText, 3],
    [ClassesPropsModel.teacherName, 4],
    [ClassesPropsModel.age, 5],
    [ClassesPropsModel.studentCount, 6],
    [ClassesPropsModel.currentUnit, 7]
]);

const ColumnSortingMap: Map<string, string> = new Map([
    [ClassesPropsModel.curriculumTypeText, 'curriculumType'],
]);

export const TrainerGridDefaultSorter = { 
    columnName: ClassesPropsModel.schoolName as string, 
    columnSortName: ClassesPropsModel.schoolName as string, 
    ascending: true 
};

export class TrainerGrid extends RoleGrid<ClassesModel> {
    
    static defaultProps = {
        searchPlaceHoderId: DashboardLocale.SearchPlaceHolderSchoolCampusClass,
        defaultSorter: TrainerGridDefaultSorter,
        mergeDataKey: ClassesPropsModel.schoolClassId, 
        mergingColumns: ClassMergedColumns
    };
    
    constructor(props, context) {
        super(props, context);
    }

    getColumns(): GLGridColumnProps<ClassesModel>[] {
        let columns: GLGridColumnProps<ClassesModel>[] = [
            {
                title: SchoolLocale.ClassesColumnSchool,
                dataIndex: ClassesPropsModel.schoolName,
                width: '10%',
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.Campuses)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Schools , {regionId: school.regionId, schoolId: school.schoolId})}" > <span>${text}</span> </a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                },
                className:'navigation-col merged-col'
            },
            {
                title: SchoolLocale.ClassCampusSelect,
                dataIndex: ClassesPropsModel.campusName,
                width: '10%',
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.Classes)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Classes , {regionId: school.regionId, schoolId: school.schoolId, campusId: school.campusId})}" > <span>${text}</span> </a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                },
                className:'navigation-col merged-col'
            },
            {
                title: SchoolLocale.ClassesColumnClass,
                dataIndex: ClassesPropsModel.schoolClassName,
                width: '10%',
                render: (text, school, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.StudentList)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.Students , {regionId: school.regionId, schoolId: school.schoolId, campusId: school.campusId, classId: school.schoolClassId})}" > <span>${text}</span> </a>`
                    }
                    else {
                        return `<span>${text}</span>`;
                    }
                },
                className:'navigation-col merged-col'
            },            
            {
                title: SchoolLocale.ClassCurriculumType,
                dataIndex: ClassesPropsModel.curriculumTypeText,
                width: '10%',
                className:'merged-col'
            },            
            {
                title: SchoolLocale.ClassesColumnTeacher,
                dataIndex: ClassesPropsModel.teacherName,
                sorter: false,
                width: '8%',
                className:'merged-col'
            },            
            {
                title: SchoolLocale.ClassesColumnAgeGrade,
                dataIndex: ClassesPropsModel.age,
                width: '9%',
                className:'merged-col'
            },
            {
                title: SchoolLocale.ClassesColumnStudentCount,
                dataIndex: ClassesPropsModel.studentCount,
                align: 'left',
                width: '8%',
                className:'merged-col'
            },
            {
                title: SchoolLocale.ClassesColumnUnit,
                dataIndex: ClassesPropsModel.currentUnit,
                align: 'left',
                width:'6%',
                className:'merged-col'
            },
            {
                title: SchoolLocale.ClassesColumnTSIREP,
                dataIndex: ClassesPropsModel.tsi_rep,
                sorter: false,
                width: "7%",
                className:'merged-col'
            },
            {
                title: SchoolLocale.ClassesColumnDuration,
                dataIndex: ClassesPropsModel.duration,
                sorter: false,
                width: "12%",
                className:'merged-col'
            },
            {
                title: SchoolLocale.ClassesColumnCount,
                dataIndex: ClassesPropsModel.count,
                sorter: false,
                width: "5%",
                className:'merged-col'
            },
            {
                title: SchoolLocale.ClassesColumnDays,
                dataIndex: ClassesPropsModel.days,
                sorter: false,
                width: "5%",
                className:'merged-col',
                render: (text, record, index) => {
                    return text ? `<span>${this.getClassDays(text)}</span>` : ''
                }
            }
        ];
        return columns;
    }

    getClassDays(days: string) {
        return days.split('/').map(day => GLGlobal.intl.formatMessage({ id: SchoolClassDayName[day] })).join('/');
    }

    getColumnSortingMap(): Map<string, string> {
        return ColumnSortingMap;
    }

}